import React, { useState } from "react"
import { Button, Input, Select, Space, Typography } from "antd"
import { CalendarOutlined, CaretDownOutlined, CaretUpOutlined, EyeOutlined, KeyOutlined } from "@ant-design/icons"
import { PublishProps } from "~/types/types"
import { DatePublish, StatusPublish, VisibilityPublish } from "./StatusPublish"

const { Title, Text } = Typography

const Publish: React.FC<PublishProps> = ({ onSubmit, isCreateEdit, setStatus, status, setPublishDate }) => {
  const [isPublishListVisible, setPublishListVisible] = React.useState(true)

  return (
    <div className="pulish border-gray-300 border rounded-[10px] mb-5 ">
      <div className="flex justify-between items-center px-4 py-2 border-b">
        <Title level={4} className="!m-0">
          Publish
        </Title>
        <Button
          onClick={() => setPublishListVisible(!isPublishListVisible)}
          className="flex items-center"
          icon={isPublishListVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
          type="text"
        ></Button>
      </div>

      {isPublishListVisible && (
        <div>
          <div className="flex justify-between mt-2 px-4 py-2">
            {isCreateEdit ? (
              <></>
            ) : (
              <>
                <div></div>
              </>
            )}
          </div>
          <Space direction="vertical" className="px-4 py-2">
            <StatusPublish setStatus={setStatus} status={status} />

            <VisibilityPublish />

            <DatePublish setPublishDate={setPublishDate} />
          </Space>
          <div className="flex justify-end border-t bg-[#f6f7f7] rounded-b-[10px]">
            <div className="px-4 py-3">
              <Button type="primary" onClick={onSubmit}>
                {isCreateEdit ? "Publish" : "Update"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Publish
