import { useAppSelector } from "~/modules/auth/services/redux/hook"
import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = useAppSelector(state => state.account.isAuthenticated)
  const [isAuthChecked, setIsAuthChecked] = useState(false)

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      setIsAuthChecked(true)
    }
  }, [isAuthenticated])

  if (!isAuthChecked) {
    return null
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />
}

export default ProtectedRoute
