import store from "~/modules/auth/services/redux/store"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import "./index.css"
import { ConfigProvider } from "antd"

createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemMarginInline: 0,
          },
        },
      }}
    >
      <StrictMode>
        <App />
      </StrictMode>
    </ConfigProvider>
  </Provider>,
)
