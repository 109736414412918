import { ErrorCodes } from "~/constants"
import { ApiNotFound, BadRequest, NetworkError, RequestError } from "./exceptions"

const { BAD_REQUEST, API_NOT_FOUND } = ErrorCodes

interface ResProps {
  message?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  status?: number
}

export const onHttpRequestError = (res: ResProps, apiName = "") => {
  // handle network error
  if (res?.message === "Network Error") {
    throw new NetworkError(res.message, apiName, null)
  }

  // handle error
  if (res && res.status) {
    const { data, status, message = "" } = res
    switch (status) {
      case BAD_REQUEST: {
        throw new BadRequest(message, apiName, data)
      }

      case API_NOT_FOUND: {
        throw new ApiNotFound("Không tìm thấy API", apiName, data)
      }
      default: {
        throw new RequestError(message, apiName, data)
      }
    }
  }

  return res
}
