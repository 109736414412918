import { HttpClient } from "~/api/HttpClient"
import { UserUpdatePayload, Filters, FilterSearchParams } from "~/types/types"
import { message } from "antd"
import { AxiosRequestConfig } from "axios"
import { keyBy } from "lodash"
import { useState } from "react"

const API_PREFIX_USERS_PATH = "/api/users"
const API_PREFIX_ROLES_PATH = "/api/users-permissions/roles"

const userService = () => {
  const [isLoading, setIsLoading] = useState(false)

  const createUser = async (updatePayload: UserUpdatePayload) => {
    setIsLoading(true)
    try {
      const response = await HttpClient.post(`${API_PREFIX_USERS_PATH}`, {
        ...updatePayload,
        name: updatePayload.username,
        email: updatePayload.email,
        role: updatePayload.role,
        firstname: updatePayload.firstName,
        lastname: updatePayload.lastName,
        language: updatePayload.language,
        emailSubscription: updatePayload.emailSubscription,
      })

      message.success("User created successfully!")

      return response
    } catch (error) {
      message.error("Failed to create user. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const getUserById = async (id: number) => {
    setIsLoading(true)
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
    }

    if (!id) {
      message.error("Invalid ID.")
      return null
    }

    try {
      const response = await HttpClient.get(`${API_PREFIX_USERS_PATH}/${id}`, { params })

      return response
    } catch (error) {
      message.error("Failed to fetch user data. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllUsers = async (searchTerms: { [key: string]: string } = {}) => {
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
      filters: {},
      sort: "id:DESC",
    }

    Object.keys(searchTerms).forEach(key => {
      const value = searchTerms[key]
      if (value) {
        params.filters[key] = { $containsi: value }
      }
    })

    try {
      const response = await HttpClient.get(`${API_PREFIX_USERS_PATH}`, { params })

      return response
    } catch (error) {
      message.error("Failed to fetch users. Please try again.")
    }
  }

  const fetchAllRoles = async (searchTerms: { [key: string]: string } = {}) => {
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
      filters: {},
      sort: "id:DESC",
    }

    Object.keys(searchTerms).forEach(key => {
      const value = searchTerms[key]
      if (value) {
        params.filters[key] = { $containsi: value }
      }
    })

    try {
      const response = await HttpClient.get(`${API_PREFIX_ROLES_PATH}`, { params })
      return response
    } catch (error) {
      message.error("Failed to fetch roles. Please try again.")
    }
  }

  const updateUser = async (id: number, updatePayload: UserUpdatePayload) => {
    setIsLoading(true)

    if (!id) {
      message.error("Invalid ID.")
      return
    }
    try {
      const response = await HttpClient.put(`${API_PREFIX_USERS_PATH}/${id}`, {
        name: updatePayload.username,
        email: updatePayload.email,
        role: updatePayload.role,
        firstname: updatePayload.firstName,
        lastname: updatePayload.lastName,
      })
      message.success("User updated successfully!")

      return response
    } catch (error) {
      message.error("Failed to update user. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const deleteUser = async (id: number) => {
    setIsLoading(true)

    if (!id) {
      message.error("Invalid ID.")
    }
    try {
      const response = await HttpClient.delete(`${API_PREFIX_USERS_PATH}/${id}`)
      message.success("User deleted successfully!")

      return response
    } catch (error) {
      message.error("Failed to delete user. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const checkEmailExists = async (email: string) => {
    return await HttpClient.get(`${API_PREFIX_USERS_PATH}?filters[email][$eq]=${email}`)
  }

  const checkUsernameExists = async (username: string) => {
    return await HttpClient.get(`${API_PREFIX_USERS_PATH}?filters[username][$eq]=${username}`)
  }

  return {
    isLoading,
    createUser,
    getUserById,
    updateUser,
    deleteUser,
    fetchAllUsers,
    fetchAllRoles,
    checkEmailExists,
    checkUsernameExists,
  }
}

export default userService
