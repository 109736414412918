import type { FormProps } from "antd"
import { Form, message } from "antd"
import Title from "antd/es/typography/Title"
import React, { useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useNavigate } from "react-router"
import { Paths } from "~/constants"
import { FieldType, MediaData } from "~/types/types"
import { addPost } from "../services/post"
import Categories from "./Categories"
import FeaturedImage from "./FeaturedImage"
import PostForm from "./PostForm"
import Publish from "./Publish"
import Tags from "./Tags"

const AddNewPost = () => {
  const [quillValue, setQuillValue] = React.useState<string>("")
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [status, setStatus] = useState("Draft")
  const [publishDate, setPublishDate] = useState<string | null>(null)

  const [selectedImage, setSelectedImage] = useState<MediaData | null>(null)
  const [selectedMetaImage, setSelectedMetaImage] = useState<MediaData | null>(null)
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const onFinish: FormProps<FieldType>["onFinish"] = async values => {
    const dataPayload = {
      name: values.name,
      slug: values.slug,
      content: quillValue,
      excerpt: values.excerpt,
      categories: selectedCategories || [],
      tags: selectedTags || [],
      publishedAt: status === "Draft" ? null : new Date().toISOString(),
      image: selectedImage,
      SEO: {
        id: 0,
        metaTile: values.metaTitle,
        metaDescription: values.metaDescription,
      },
    }

    try {
      await addPost({ data: dataPayload })

      message.success("Post added successfully!")

      form.resetFields()

      navigate(Paths.POSTS.LIST.PATH)
    } catch (error) {
      message.error("Failed to add post. Please try again.")
    }
  }

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {}

  const onChangeCategories = (checkedValues: string[]) => {
    setSelectedCategories(checkedValues)
  }
  const onChangeTags = (checkedValues: string[]) => {
    setSelectedTags(checkedValues)
  }
  const handleSelectMedia = (media: MediaData | null) => {
    setSelectedImage(media)
  }

  const onChangeMetaImage = (media: MediaData | null) => {
    setSelectedMetaImage(media)
  }

  return (
    <div className="p-4">
      <Title level={2} className="m-0">
        Add New Post
      </Title>
      <div className="flex justify-between">
        <PostForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          quillValue={quillValue}
          setQuillValue={setQuillValue}
          onChangeMetaImage={onChangeMetaImage}
        />
        <div className=" w-[22%] pt-2 ">
          <Publish
            onSubmit={form.submit}
            isCreateEdit={true}
            setStatus={setStatus}
            status={status}
            setPublishDate={setPublishDate}
          />
          <Categories selectedCategories={selectedCategories} onChangeCategories={onChangeCategories} />
          <Tags selectedTags={selectedTags} onChangeTags={onChangeTags} />
          <FeaturedImage selectedMedia={selectedImage} setSelectedMedia={handleSelectMedia} />
        </div>
      </div>
    </div>
  )
}

export default AddNewPost
