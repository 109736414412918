import { FieldType, PageFormProps } from "~/types/types";
import { slugPattern } from "~/utils/validationPatterns";
import { Form, Input } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import { CONFIG_QUILL_MODULE, CONFIG_QUILL_FORMAT } from "~/constants/constants";

const PageForm: React.FC<PageFormProps> = ({ form, onFinishFailed, quillValue, setQuillValue, onFinish }) => {
  const quillRef = React.useRef<ReactQuill>(null);

  return (
    <Form
      form={form}
      className="flex flex-col space-y-3 w-[83%]"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item<FieldType>
        name="name"
        rules={[{ required: true, message: "Please input your username!" }]}
        className="!mb-0"
      >
        <Input placeholder="Add Name" className="py-2 text-xl custom-input" />
      </Form.Item>

      <Form.Item<FieldType>
        name="slug"
        rules={[
          {
            pattern: slugPattern,
            message: 'Slug must match the following: "A-Z, a-z, 0-9, - ,_ ,. ,~"',
          },
        ]}
        label={<span className="text-base font-medium">Slug</span>}
        extra={<span className="text-sm">May not need to be entered (automatically render by name)</span>}
      >
        <Input placeholder="Slug" className="py-2 custom-input" />
      </Form.Item>

      <Form.Item<FieldType> name="content" className="h-[450px] pt-3">
        <ReactQuill
          ref={quillRef}
          value={quillValue}
          onChange={setQuillValue}
          className="h-[400px]"
          modules={CONFIG_QUILL_MODULE}
          formats={CONFIG_QUILL_FORMAT}
        />
      </Form.Item>
    </Form>
  );
};

export default PageForm;