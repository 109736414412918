import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons"
import { Button, Select, Space } from "antd"
import Title from "antd/es/typography/Title"
import { debounce } from "lodash"
import React, { useCallback, useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { fetchMediaAll } from "../services/apiServices"
import MediaList from "./MediaList"
import SearchInput from "./SearchInput"
import { MediaData } from "~/types/types"

interface MediaProp {
  isOpenModal?: boolean
  onSelectMedia?: (media: MediaData) => void
}
const Media = ({ isOpenModal, onSelectMedia }: MediaProp) => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [isView, setIsView] = useState(true)
  const [totalItems, setTotalItems] = useState<number>(0)

  const handleSearchChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = e.target.value.trim().replace(/\s+/g, " ").toLowerCase()
      setSearchTerm(cleanedValue)
    }, 500),
    [],
  )

  const fetchMediaData = async () => {
    try {
      const response = await fetchMediaAll()
      setTotalItems(response.length)
    } catch (error) {
      console.error("Error fetching media data", error)
    }
  }

  useEffect(() => {
    fetchMediaData()
  }, [])

  return (
    <div className="p-4 pt-0">
      {isOpenModal || (
        <div className="flex space-x-4 items-center mt-4">
          <Title level={2} className="!m-0">
            Media Library
          </Title>
          <Button
            onClick={() => navigate("/upload/create")}
            className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
          >
            Add New Media File
          </Button>
        </div>
      )}
      <div className="lg:flex lg:space-x-0 space-y-2 justify-between mt-5 py-3 px-4 border border-[#a5a1a18e] ">
        <Space>
          {isOpenModal || (
            <Space className="view">
              <Button onClick={() => setIsView(true)}>
                <BarsOutlined />
              </Button>
              <Button onClick={() => setIsView(false)}>
                <AppstoreOutlined />
              </Button>
            </Space>
          )}
          <Select
            defaultValue="All media items"
            style={{ width: 150 }}
            options={[
              { value: "allMedia", label: "All media items" },
              { value: "images", label: "Images" },
              { value: "unattached", label: "Unattached" },
              { value: "mine", label: "Mine" },
            ]}
          />
          <Space className="flex space-x-3 items-center">
            <Select
              defaultValue="All Date"
              style={{ width: 150 }}
              options={[
                { value: "all", label: "All Date" },
                { value: "June 2024", label: "June 2024" },
                { value: "July 2024", label: "July 2024" },
              ]}
            />
            <Button>Filter</Button>
          </Space>
        </Space>
        <SearchInput handleSearchChange={handleSearchChange} />
      </div>
      {isOpenModal || (
        <div className="flex justify-between items-center my-4">
          <Space className=" space-x-5">
            <Space className="space-x-3">
              <Select
                defaultValue="Bulk Action"
                style={{ width: 120 }}
                options={[{ value: "all", label: "Bulk Action" }]}
              />
              <Button>Apply</Button>
            </Space>
          </Space>
          <div>
            <span> {totalItems} items</span>
          </div>
        </div>
      )}
      <MediaList
        searchTerm={searchTerm}
        isOpenModal={isOpenModal}
        isView={isOpenModal === true ? false : isView}
        onSelectMedia={onSelectMedia}
      />
    </div>
  )
}

export default Media
