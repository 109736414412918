import { Route, Routes } from "react-router-dom"
import MediaList from "./pages/Media"
import AddNewMedia from "./pages/AddNewMedia"
import EditMedia from "./pages/EditMedia"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<MediaList />} />
      <Route path="/create" element={<AddNewMedia />} />
      <Route path="/:id" element={<EditMedia />} />
    </Routes>
  )
}
