import { useEffect, useState } from "react"
import { Button, Card, Checkbox, Input, message, Modal, Select, Table, TableColumnsType, Tabs, Typography } from "antd"
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
import menuService from "../service/menuService"
import { ReactSortable, SortableEvent } from "react-sortablejs"
import { ContentHomePage, ModalHomePage } from "./Content"
import { CategoryItem, DataType, MenuItem, MenuPreference, PageItem, PostItem } from "~/types/types"
import { HttpClient } from "~/api/HttpClient"

const { Title } = Typography
const { Option } = Select

const MenusListing = () => {
  const [mainTab, setMainTab] = useState("tab1")
  const [subTab, setSubTab] = useState("tabMos")
  const [expandedPages, setExpandedPages] = useState(true)
  const [expandedPosts, setExpandedPosts] = useState(false)
  const [expandedCategories, setExpandedCategories] = useState(false)
  const [mostRecentPages, setMostRecentPages] = useState<PageItem[]>([])
  const [mostRecentPosts, setMostRecentPosts] = useState<PostItem[]>([])
  const [mostRecentCategories, setMostRecentCategories] = useState<CategoryItem[]>([])
  const [menuPreference, setMenuPreference] = useState<MenuPreference>()
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null)
  const [expandedItem, setExpandedItem] = useState<string | null>(null)
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [openModalId, setOpenModalId] = useState<string | null>(null)
  const [selectedPages, setSelectedPages] = useState<Set<number>>(new Set())
  const [selectedPosts, setSelectedPosts] = useState<Set<number>>(new Set())
  const [selectedCategories, setSelectedCategories] = useState<Set<number>>(new Set())
  const [menuName, setMenuName] = useState("")
  const [draggingItem, setDraggingItem] = useState(null)
  const [activeTab, setActiveTab] = useState("pages")

  const API_PREFIX_MENUPREFERENCE_PATH = "/api/menu-preferences"

  const showModal = (id: string) => {
    setOpenModalId(id)
  }

  const handleSelectMenuChange = async (value: string) => {
    setSelectedMenu(value)
    const selectedItem = Array.isArray(menuPreference) ? menuPreference.find(item => item.id === value) : undefined
    setMenuName(selectedItem ? selectedItem.attributes.name : "")

    const selectedMenuPreference = Array.isArray(menuPreference)
      ? menuPreference.find(item => item.id === value)
      : undefined

    if (selectedMenuPreference) {
      const subItems = selectedMenuPreference.attributes.items || []
      setMenuItems(subItems)
    } else {
      setMenuItems([])
    }
  }

  useEffect(() => {
    if (Array.isArray(menuPreference) && menuPreference.length > 0) {
      const defaultItem = menuPreference.find(item => item.id === selectedMenu)
      setMenuName(defaultItem ? defaultItem.attributes.name : "")
    }
  }, [menuPreference, selectedMenu])

  const handleOk = () => {
    setOpenModalId(null)
  }

  const handleCancel = () => {
    setOpenModalId(null)
  }

  const toggleExpand = (id: string) => {
    setExpandedItem(expandedItem === id ? null : id)
  }

  const handleMainTabChange = (key: string) => {
    setMainTab(key)
  }

  const handleSubTabChange = (key: string) => {
    setSubTab(key)
  }

  const fetchPages = async () => {
    try {
      const response = await menuService.getAllPages()
      const data = response.data || []
      const items = data.map((page: { attributes: { name: string; slug: string } }) => ({
        name: page.attributes.name,
        slug: page.attributes.slug,
      }))
      setMostRecentPages(items)
    } catch (error) {
      message.error("Error fetching pages. Please try again.")
    }
  }

  const fetchPosts = async () => {
    try {
      const response = await menuService.getAllPosts()
      const data = response.data || []
      const items = data.map((post: { attributes: { name: string; slug: string } }) => ({
        name: post.attributes.name,
        slug: post.attributes.slug,
      }))
      setMostRecentPosts(items)
    } catch (error) {
      message.error("Error fetching post. Please try again.")
    }
  }

  const fetchCategories = async () => {
    try {
      const response = await menuService.getAllCategories()
      const data = response.data || []
      const items = data.map((category: { attributes: { name: string; slug: string } }) => ({
        name: category.attributes.name,
        slug: category.attributes.slug,
      }))
      setMostRecentCategories(items)
    } catch (error) {
      message.error("Error fetching post. Please try again.")
    }
  }

  const fetchMenuPreferences = async () => {
    try {
      const response = await menuService.getAllMenuPreference()
      const data = response.data

      if (data.length > 0) {
        setMenuPreference(data)

        const defaultItem = data[0].attributes

        if (defaultItem) {
          setSelectedMenu(data[0].id)
          const subItems = defaultItem.items || []
          setMenuItems(subItems)
        }
      } else {
        console.log("No items found")
        setMenuItems([])
      }
    } catch (error) {
      console.error("Error fetching menu preferences:", error)
    }
  }

  const addToMenu = () => {
    const maxId = menuItems.reduce((max, item) => (item.id > max ? item.id : max), 0)

    const newMenuItems = checkedItems.map((item, index) => {
      let slug = ""

      const pageItem = mostRecentPages.find(page => page.name === item)
      if (pageItem) {
        slug = pageItem.slug
      } else {
        const postItem = mostRecentPosts.find(post => post.name === item)
        if (postItem) {
          slug = postItem.slug
        } else {
          const categoryItem = mostRecentCategories.find(category => category.name === item)
          slug = categoryItem ? categoryItem.slug : ""
        }
      }

      return {
        id: maxId + index + 1,
        name: item,
        slug,
        parent: 0,
        order: menuItems.length + index,
      }
    })

    setMenuItems(prevMenuItems => [...prevMenuItems, ...(newMenuItems as MenuItem[])])

    setCheckedItems([])
    setSelectedPosts(new Set())
    setSelectedCategories(new Set())
  }

  const saveMenu = async () => {
    const menuData = {
      name: menuName,
      items: menuItems,
    }

    try {
      const response = await HttpClient.put(`${API_PREFIX_MENUPREFERENCE_PATH}/${selectedMenu}`, {
        data: {
          name: menuData.name,
          items: menuData.items,
        },
      })

      message.success("Menu saved successfully!")
      console.log("Menu saved:", response.data)
    } catch (error) {
      message.error("Failed to save menu. Please try again.")
      console.error("Error saving menu:", error)
    }
  }

  const handleRemove = (id: number) => {
    setMenuItems(prevMenuItems => {
      const updatedMenuItems = prevMenuItems.filter(item => item.id !== id)
      return updatedMenuItems
    })
  }

  const handleOrderChange = (newMenuItems: MenuItem[]) => {
    const updatedMenuItems = newMenuItems.map((item, index) => ({
      ...item,
      order: index,
    }))

    setMenuItems(updatedMenuItems)
  }

  const handleEnd = (event: SortableEvent) => {
    // @TODO: @ThangPH - Implement this function
    // const { newIndex, oldIndex } = event
    // const updatedItems = [...menuItems]
    // const movedItem = updatedItems.splice(oldIndex, 1)[0]
    // updatedItems.splice(newIndex, 0, movedItem)
    // if (newIndex > oldIndex) {
    //   movedItem.parent = (movedItem.parent ?? 0) - 1
    // } else {
    //   movedItem.parent = (movedItem.parent ?? 0) + 1
    // }
    // setMenuItems(updatedItems)
  }

  const handleCheckboxChange = (e: any, item: string) => {
    const { checked } = e.target
    setCheckedItems(prev => {
      if (checked) {
        return [...prev, item]
      } else {
        return prev.filter(i => i !== item)
      }
    })
  }

  const handlePageSelectAll = () => {
    const allPageNames = mostRecentPages.map(page => page.name)
    const areAllChecked = allPageNames.every(name => checkedItems.includes(name))

    if (areAllChecked) {
      setCheckedItems([])
    } else {
      setCheckedItems(allPageNames)
    }
    setSelectedPosts(new Set())
    setSelectedCategories(new Set())
  }

  const handlePostSelectAll = () => {
    const allPostNames = mostRecentPosts.map(post => post.name)
    const areAllChecked = allPostNames.every(name => checkedItems.includes(name))

    if (areAllChecked) {
      setCheckedItems([])
    } else {
      setCheckedItems(allPostNames)
    }
    setSelectedPages(new Set())
    setSelectedCategories(new Set())
  }

  const handleCategorySelectAll = () => {
    const allCategoryNames = mostRecentCategories.map(category => category.name)
    const areAllChecked = allCategoryNames.every(name => checkedItems.includes(name))

    if (areAllChecked) {
      setCheckedItems([])
    } else {
      setCheckedItems(allCategoryNames)
    }
    setSelectedPages(new Set())
    setSelectedPosts(new Set())
  }

  useEffect(() => {
    if (expandedPages && subTab === "tabMos") {
      fetchPages()
      fetchPosts()
      fetchCategories()
      fetchMenuPreferences()
    }
  }, [])

  const togglePagesExpand = () => {
    setExpandedPages(!expandedPages)
    if (!expandedPages) {
      setExpandedPosts(false)
      setExpandedCategories(false)
    }
  }

  const togglePostsExpand = () => {
    setExpandedPosts(!expandedPosts)
    if (!expandedPosts) {
      setExpandedPages(false)
      setExpandedCategories(false)
    }
  }

  const toggleCategoryExpand = () => {
    setExpandedCategories(!expandedCategories)
    if (!expandedCategories) {
      setExpandedPages(false)
      setExpandedPosts(false)
    }
  }

  const columns: TableColumnsType<DataType> = [
    { title: "Theme Location", dataIndex: "theme", key: "theme" },
    {
      title: "	Assigned Menu",
      dataIndex: "menu",
      className: "w-[100px]",
      key: "menu",
      render: () => (
        <Select defaultValue="-Select Menu-">
          <Option value="primary">Primary</Option>
          <Option value="primary2">Primary (2)</Option>
        </Select>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        const isSpecialMenu = ["Secondary Menu", "Logged In Account Menu", "Footer Menu"].includes(record.theme)
        return (
          <div className="text-blue-500">
            {isSpecialMenu ? (
              <a href="#">Use New Menu</a>
            ) : (
              <>
                <a href="#">Edit</a>
                <span className="mx-1">|</span>
                <a href="#">Use New Menu</a>
              </>
            )}
          </div>
        )
      },
    },
  ]

  const data: DataType[] = [
    {
      key: 1,
      theme: "Primary Menu",
      menu: "",
    },
    {
      key: 2,
      theme: "Secondary Menu",
      menu: "",
    },
    {
      key: 3,
      theme: "Off-Canvas Menu",
      menu: "",
    },
    {
      key: 4,
      theme: "Logged In Account Menu",
      menu: "",
    },
    {
      key: 5,
      theme: "Footer Menu",
      menu: "",
    },
  ]

  return (
    <div>
      <div>
        <div className="flex py-1 items-center justify-between">
          <div className="flex space-x-4 items-center">
            <Title level={2} className="m-0">
              Menus
            </Title>
            <Button
              type="primary"
              className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
            >
              Manage with Live Preview
            </Button>
          </div>
        </div>
        <Tabs
          onChange={handleMainTabChange}
          type="card"
          activeKey={mainTab}
          items={[
            {
              label: `Edit Menus`,
              key: "tab1",
            },
            {
              label: `Manage Locations`,
              key: "tab2",
            },
          ]}
        ></Tabs>
        <div>
          {mainTab === "tab1" && (
            <div className="flex items-center p-[10px] border border-1">
              <span style={{ marginRight: 10 }}>Select a menu to edit:</span>
              <Select value={selectedMenu} style={{ width: 200 }} onChange={handleSelectMenuChange}>
                {Array.isArray(menuPreference) &&
                  menuPreference.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.attributes.name}
                    </Select.Option>
                  ))}
              </Select>

              <p className="mx-[5px]">or</p>
              <a href="#" className="mx-[5px] text-blue-500">
                create a new menu
              </a>
              <p> Do not forget to save your changes!</p>
            </div>
          )}
        </div>
        <div>
          {mainTab === "tab1" && (
            <div className="flex pt-[30px]">
              <div className="w-[25%]  ">
                <div>
                  <Title level={3} className="m-0">
                    Add menu items
                  </Title>
                </div>
                {/* Pages */}
                <div className="border border-1">
                  <div className="flex justify-between p-[10px] border-b-" onClick={togglePagesExpand}>
                    <p>Pages</p>
                    <p>{expandedPages ? <CaretUpOutlined /> : <CaretDownOutlined />}</p>
                  </div>
                  <div
                    className={`px-[10px]   transition-all duration-300 ${
                      expandedPages ? "max-h-[400px] overflow-hidden" : "max-h-0 overflow-hidden"
                    }`}
                  >
                    <Tabs
                      onChange={handleSubTabChange}
                      type="card"
                      activeKey={subTab}
                      items={[
                        {
                          label: `Most Recent`,
                          key: "tabMos",
                        },
                        {
                          label: `View All`,
                          key: "tabView",
                        },
                        {
                          label: `Search`,
                          key: "tabSearch",
                        },
                      ]}
                    />
                    {subTab === "tabMos" && (
                      <div>
                        <div className="flex flex-col ">
                          <div className="flex flex-col space-y-2 max-h-[200px] overflow-y-auto p-[10px] flex-1 border border-1">
                            {mostRecentPages.map((item, index) => (
                              <Checkbox
                                key={index}
                                checked={checkedItems.includes(item.name)}
                                onChange={e => handleCheckboxChange(e, item.name)}
                              >
                                {item.name}
                              </Checkbox>
                            ))}
                          </div>
                        </div>
                        <div className="flex justify-between py-[10px] items-center">
                          <Checkbox
                            onClick={handlePageSelectAll}
                            checked={mostRecentPages.every(page => checkedItems.includes(page.name))}
                          >
                            Select All
                          </Checkbox>
                          <Button onClick={addToMenu}>Add to menu</Button>
                        </div>
                      </div>
                    )}
                    {subTab === "tabView" && <p>Nội dung TabView</p>}
                    {subTab === "tabSearch" && <p>Nội dung TabSearch</p>}
                  </div>
                </div>
                {/* Posts */}
                <div className="border border-1 ">
                  <div className="flex justify-between p-[10px] border-b-2" onClick={togglePostsExpand}>
                    <p>Post</p>
                    <p>{expandedPosts ? <CaretUpOutlined /> : <CaretDownOutlined />}</p>
                  </div>

                  <div
                    className={`px-[10px] transition-all duration-300 ${
                      expandedPosts ? "max-h-[400px] overflow-hidden" : "max-h-0 overflow-hidden"
                    }`}
                  >
                    <Tabs
                      onChange={handleSubTabChange}
                      type="card"
                      activeKey={subTab}
                      items={[
                        {
                          label: `Most Recent`,
                          key: "tabMos",
                        },
                        {
                          label: `View All`,
                          key: "tabView",
                        },
                        {
                          label: `Search`,
                          key: "tabSearch",
                        },
                      ]}
                    />
                    {subTab === "tabMos" && (
                      <div>
                        <div className="flex flex-col space-y-2 max-h-[200px] p-[10px] overflow-y-auto px-[10px] flex-1 border border-1">
                          {mostRecentPosts.map((item, index) => (
                            <Checkbox
                              key={index}
                              checked={checkedItems.includes(item.name)}
                              onChange={e => handleCheckboxChange(e, item.name)}
                            >
                              {item.name}
                            </Checkbox>
                          ))}
                        </div>
                        <div className="flex justify-between py-[10px] items-center">
                          <Checkbox
                            onClick={handlePostSelectAll}
                            checked={mostRecentPosts.every(post => checkedItems.includes(post.name))}
                          >
                            Select All
                          </Checkbox>
                          <Button onClick={addToMenu}>Add to menu</Button>
                        </div>
                      </div>
                    )}
                    {subTab === "tabView" && <p>Nội dung TabView</p>}
                    {subTab === "tabSearch" && <p>Nội dung TabSearch</p>}
                  </div>
                </div>
                {/* Categories */}
                <div className="border border-1">
                  <div className="flex justify-between p-[10px] border-b-2" onClick={toggleCategoryExpand}>
                    <p>Category</p>
                    <p>{expandedCategories ? <CaretUpOutlined /> : <CaretDownOutlined />}</p>
                  </div>

                  <div
                    className={`px-[10px] transition-all duration-300 ${
                      expandedCategories ? "max-h-[400px] overflow-hidden" : "max-h-0 overflow-hidden"
                    }`}
                  >
                    <Tabs
                      onChange={handleSubTabChange}
                      type="card"
                      activeKey={subTab}
                      items={[
                        {
                          label: `Most Recent`,
                          key: "tabMos",
                        },
                        {
                          label: `View All`,
                          key: "tabView",
                        },
                        {
                          label: `Search`,
                          key: "tabSearch",
                        },
                      ]}
                    />
                    {subTab === "tabMos" && (
                      <div>
                        <div className="flex flex-col space-y-2 max-h-[200px] p-[10px] overflow-y-auto px-[10px] flex-1 border border-1">
                          {mostRecentCategories.map((item, index) => (
                            <Checkbox
                              key={index}
                              checked={checkedItems.includes(item.name)}
                              onChange={e => handleCheckboxChange(e, item.name)}
                            >
                              {item.name}
                            </Checkbox>
                          ))}
                        </div>
                        <div className="flex justify-between py-[10px] items-center">
                          <Checkbox
                            onClick={handleCategorySelectAll}
                            checked={mostRecentCategories.every(category => checkedItems.includes(category.name))}
                          >
                            Select All
                          </Checkbox>
                          <Button onClick={addToMenu}>Add to menu</Button>
                        </div>
                      </div>
                    )}
                    {subTab === "tabView" && <p>Nội dung TabView</p>}
                    {subTab === "tabSearch" && <p>Nội dung TabSearch</p>}
                  </div>
                </div>
              </div>
              <div className="w-[70%] ml-[5%]">
                <div>
                  <Title level={3} className="m-0">
                    Menu structure
                  </Title>
                </div>
                <div className="border border-1">
                  <div className="flex bg-gray-100 border border-b-gray-200 p-[10px] relative">
                    <p className="w-[10%]">Menu Name</p>
                    <Input value={menuName} placeholder="Menu Name" className="mb-[10px]" readOnly />
                  </div>

                  <div className="px-[10px]">
                    <div className="p-4">
                      <p>
                        Drag the items into the order you prefer. Click the arrow on the right of the item to reveal
                        additional configuration options.
                      </p>
                    </div>
                    <div>
                      {menuItems.length > 0 && (
                        <ReactSortable
                          list={menuItems}
                          setList={handleOrderChange}
                          group={{ name: "menu", pull: true, put: true }}
                          animation={350}
                          onEnd={handleEnd}
                        >
                          {menuItems
                            .sort((a, b) => a.order - b.order)
                            .map(item => (
                              <Card
                                className="w-[40%] items-center mb-[10px]"
                                key={item.id}
                                style={{ marginLeft: `${(item.parent ?? 0) * 40}px` }}
                              >
                                <div className="flex justify-between items-center">
                                  <div>
                                    <h2>{item.name || "No Title"}</h2>
                                  </div>
                                  <div className="flex">
                                    <Button type="primary" onClick={() => showModal(item.id.toString())}>
                                      Open Modal
                                    </Button>
                                    <Modal
                                      open={openModalId === item.id.toString()}
                                      onOk={handleOk}
                                      onCancel={handleCancel}
                                    >
                                      <ModalHomePage title={item.name} />
                                    </Modal>
                                    <Button
                                      type="text"
                                      onClick={() => toggleExpand(item.id.toString())}
                                      icon={
                                        expandedItem === item.id.toString() ? (
                                          <CaretUpOutlined />
                                        ) : (
                                          <CaretDownOutlined />
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                {expandedItem === item.id.toString() && (
                                  <div>
                                    <ContentHomePage title={item.name} onRemove={() => handleRemove(item.id)} />
                                  </div>
                                )}
                              </Card>
                            ))}
                        </ReactSortable>
                      )}
                    </div>
                  </div>
                  <div className="border border-1 w-full p-2   bg-gray-100 sticky bottom-0">
                    <Button onClick={saveMenu}>Save Menu</Button>
                    <a href="#">Delete Menu</a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {mainTab === "tab2" && (
          <div>
            <p className="mb-[10px]">Your theme supports 5 menus. Select which menu appears in each location.</p>
            <Table className="w-[800px] border border-1" columns={columns} dataSource={data} pagination={false} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MenusListing
