import { Route, Routes } from "react-router-dom"
import UsersListing from "./pages/UsersListing"
import UpsertForm from "./pages/UpsertForm"

export const UserRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<UsersListing />} />
        <Route path="/create" element={<UpsertForm editMode={false} />} />
        <Route path="/:id" element={<UpsertForm editMode={true} />} />
      </Routes>
    </div>
  )
}
