import { HttpClient } from "~/api/HttpClient"
import { AddCategoryPayload, AttributesCategory, FilterSearchParams } from "~/types/types"
import { message } from "antd"
import { AxiosRequestConfig } from "axios"

export const API_PREFIX_POST_PATH = "/api/posts"
export const API_PREFIX_TAG_PATH = "/api/tags"

export const fetchPostAll = async () => {
  return await HttpClient.get(`${API_PREFIX_POST_PATH}`)
}

export const fetchPosts = async (searchTerms: { [key: string]: string } = {}) => {
  const params: FilterSearchParams = {
    populate: "*",
    sort: "createdAt:desc",
    publicationState: "preview",
    filters: {},
  }

  Object.keys(searchTerms).forEach(key => {
    const value = searchTerms[key]
    if (value) {
      params.filters[key] = { $containsi: value }
    }
  })

  try {
    const response = await HttpClient.get(`${API_PREFIX_POST_PATH}`, { params })

    const modifiedResponse = response.data.map((post: any) => ({
      ...post,
      status: post.attributes.publishedAt ? "published" : "draft",
      publishedAt: post.attributes.publishedAt,
    }))

    return modifiedResponse
  } catch (error) {
    message.error("Không thể lấy bài viết. Vui lòng thử lại.")
  }
}

export const fetchPostId = async (id: number) => {
  const response = await HttpClient.get(`${API_PREFIX_POST_PATH}/${id}?populate=*`)
  return response.data
}

export const deletePost = async (id: number) => {
  return await HttpClient.delete(`${API_PREFIX_POST_PATH}/${id}`)
}
export const updatePost = async (id: number, data: any) => {
  return await HttpClient.put(`${API_PREFIX_POST_PATH}/${id}`, data)
}
export const addPost = async (data: any) => {
  return await HttpClient.post(`${API_PREFIX_POST_PATH}`, data)
}

export const fetchCategories = async () => {
  return await HttpClient.get(`/api/cateogries`)
}

export const fetchTags = async () => {
  return await HttpClient.get(`/api/tags`)
}

export const createTag = async (data: AttributesCategory) => {
  return await HttpClient.post(`${API_PREFIX_TAG_PATH}`, { data })
}

export const checkSlug = async (slug: string) => {
  const params: AxiosRequestConfig["params"] = {
    filters: {
      slug: { $eq: slug },
    },
    publicationState: "preview",
  }
  return await HttpClient.get(`${API_PREFIX_POST_PATH}`, { params })
}
