import { useEffect, useState } from "react"
import { Form, Input, Flex, Button, message, Typography } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { getCategoryById, updateCategory } from "../services/category"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { Paths } from "~/constants"
import Title from "antd/es/typography/Title"

const FormEditCategory: React.FC = () => {
  const [form] = Form.useForm()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true)
        if (id) {
          const response = await getCategoryById(Number(id))

          if (response) {
            form.setFieldsValue({
              name: response.data.attributes.name,
              slug: response.data.attributes.slug,
              description: response.data.attributes.description,
            })
          }
          setLoading(false)
        }
      } catch {
        message.error("Failed to fetch category")
        setLoading(false)
      }
    }

    fetchCategory()
  }, [id, form])

  const handleSubmit = async () => {
    try {
      if (id) {
        const values = form.getFieldsValue()
        await updateCategory(id, values)
        navigate(`${Paths.CATEGORIES.LIST.PATH}`)
        message.success("Category updated successfully")
      }
    } catch {
      message.error("Failed to update category")
    }
  }

  return (
    <div style={{ width: "90%", float: "left" }} className="ml-4">
      <Title level={2} className="mt-5">
        Edit Category
      </Title>

      <Form
        labelAlign="left"
        labelCol={{ span: 5 }}
        form={form}
        onFinish={handleSubmit}
        className="mt-7"
        style={{ maxWidth: 800 }}
      >
        <Flex gap="small" vertical align="flex-start" justify="space-between">
          <Form.Item
            style={{ marginBottom: 20, width: "100%" }}
            name="name"
            label={<span className="text-base font-medium">Name</span>}
            rules={[{ required: true, message: "Tối thiểu 10 ký tự!", min: 10 }]}
            extra="The name is how it appears on your site."
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 20 }}
            name="slug"
            label={<span className="text-base font-medium">Slug</span>}
            rules={[{ required: true, message: "Không được để trống!" }]}
            extra="The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens."
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label={<span className="text-base font-medium">Description</span>}
            rules={[{ required: true, message: "Không được để trống!" }]}
            extra="The description is not prominent by default; however, some themes may show it."
          >
            <Input.TextArea className="w-[700px]" rows={6} />
          </Form.Item>
          <Form.Item className="mt-6">
            <Flex>
              <Button
                icon={<CheckCircleOutlined />}
                style={{ marginRight: "20px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Update Category
              </Button>
              <Button icon={<CloseCircleOutlined />} danger onClick={() => navigate(`${Paths.CATEGORIES.LIST.PATH}`)}>
                Cancel
              </Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
    </div>
  )
}

export default FormEditCategory
