import { Route, Routes } from "react-router-dom"
import List from "./pages/List"
import FormEditCategory from "./pages/FormEditCategory"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/:id" element={<FormEditCategory />} />
    </Routes>
  )
}
