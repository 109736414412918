import { Menu } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import Sider from "antd/es/layout/Sider"
import { menuItems } from "./menuItems"

export const LayoutSider = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate()
  const location = useLocation()

  // height: 70px;
  //   text-align: center;
  //   width: 100%;
  //   display: flex;
  //   justify-items: center;
  //   align-items: center;
  //   padding: 0 1rem;

  return (
    <Sider trigger={null} breakpoint="lg" width={200} collapsible collapsed={collapsed}>
      <div
        style={{
          height: 70,
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          padding: "0 1rem",
        }}
      >
        {collapsed ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://media.discordapp.net/attachments/1226447472046510104/1281946015787913287/image.png?ex=66e22e03&is=66e0dc83&hm=ca01a6c9d81591acab80e278d7adf63dd5e38af0b4af10a3d1d02e4b059f1229&=&format=webp&quality=lossless&width=1314&height=1314"
              alt=""
              style={{ height: "50px", width: "45px", borderRadius: "10px", marginRight: "20px" }}
            />
            <p style={{ fontSize: "15px", color: "white" }}></p>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src="https://media.discordapp.net/attachments/1226447472046510104/1281946015787913287/image.png?ex=66e22e03&is=66e0dc83&hm=ca01a6c9d81591acab80e278d7adf63dd5e38af0b4af10a3d1d02e4b059f1229&=&format=webp&quality=lossless&width=1314&height=1314"
              alt=""
              style={{ height: "50px", width: "45px", borderRadius: "10px", marginRight: "20px" }}
            />
            <p style={{ fontSize: "15px", color: "white" }}>Donghwa CMS</p>
          </div>
        )}
      </div>
      <Menu
        mode="inline"
        theme="dark"
        items={menuItems}
        onClick={info => {
          navigate(info.key)
        }}
        selectedKeys={[location.pathname]} // Chọn key dựa trên pathname
        className="custom-menu"
        style={{ minWidth: 0, flex: "auto" }}
      />
    </Sider>
  )
}
