import { Button, Input, Table, Select, Space, Popconfirm, Tabs, Typography, message, Card } from "antd"
import { useState, useEffect, useRef, useCallback } from "react"
import { DeleteOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons"
import queryString from "query-string"
import { useNavigate } from "react-router-dom"
import userService from "../services/userService"
import { UserUpdatePayload } from "~/types/types"
import { USERS } from "~/constants/Paths"
import Filters, { FilterComponent } from "~/components/Filters"
import { normalizeText } from "~/utils/helper"

function UsersListing() {
  const isLoading = userService().isLoading
  const navigate = useNavigate()
  const { Title } = Typography
  const [loading, setLoading] = useState(false)
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [userNameFill, setUserNameFill] = useState<{ value: string | number; label: string }[]>([])
  const [emailOptions, setEmailOptions] = useState<{ value: string; label: string }[]>([])
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [dataSource, setDataSource] = useState<UserUpdatePayload[]>([])
  const [totalUsers, setTotalUsers] = useState(0)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [adminCount, setAdminCount] = useState(0)
  const [filterUser, setFilterUser] = useState("all")
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)

  const userServices = userService()

  const onRefresh = () => {
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
    setNeedRefresh(!needRefresh)
  }

  const columns = [
    {
      title: <span>ID</span>,
      dataIndex: "id",
      key: "id",
      showSorterTooltip: false,
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: { id: number; name: string }) => {
        return <>{role?.name || "Subscriber"}</>
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id: number) => (
        <>
          <Space size="middle" className="justify-center items-center">
            <Button
              onClick={() => handleEditUser(id)}
              type="primary"
              icon={<EditOutlined />}
              className="bg-blue-500 px-4 py-2 rounded"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this user?"
              onConfirm={() => handleDeleteUser(id)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button danger type="primary" icon={<DeleteOutlined />} className=" ">
                <span className="">Delete</span>
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ]

  const fetchUsers = async (searchTerms: { [key: string]: string } = {}) => {
    try {
      const updatePayload = await userServices.fetchAllUsers(searchTerms)
      if (updatePayload && updatePayload.length > 0) {
        setDataSource(updatePayload)
        setTotalUsers(updatePayload.length)
        setAdminCount(updatePayload.filter((data: { role: { id: number } }) => data.role.id === 1).length)
        setUserNameFill(
          updatePayload.map((user: { id: number; username: string }) => ({
            value: user.id,
            label: user.username,
          })),
        )

        const uniqueEmails: string[] = Array.from(
          new Set<string>(updatePayload.map((user: { email: string }) => user.email)),
        )

        setEmailOptions(
          uniqueEmails.map((email: string) => ({
            value: email,
            label: email,
          })),
        )
      } else {
        setDataSource([])
        setTotalUsers(0)
        setAdminCount(0)
        setUserNameFill([])
        setEmailOptions([])
      }
    } catch (error) {
      message.error("Error fetching users")
    }
  }

  const handleTabChange = (key: string) => {
    setFilterUser(key)
  }

  const handleCreateUser = () => {
    const createPath = USERS.CREATE.PATH
    navigate(createPath)
    fetchUsers()
  }

  const handleEditUser = (id: number) => {
    const editPath = USERS.EDIT.PATH.replace(":idUser", id.toString())
    navigate(editPath)
    fetchUsers()
  }

  const handleDeleteUser = async (id: number) => {
    const success = await userServices.deleteUser(id)
    if (success) {
      setNeedRefresh(!needRefresh)
    }
  }

  const onSearch = useCallback(
    (filters: object) => {
      const values: { [key: string]: string } = {}

      Object.keys(filters).forEach(key => {
        const value = filters[key as keyof typeof filters]
        if (typeof value === "string") {
          values[key] = value
        }
      })

      if (!values) {
        message.error("Values are undefined")
        return
      }

      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      const searchParams = queryString.stringify(newValue, { encode: false })
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues, navigate],
  )

  useEffect(() => {
    fetchUsers()
  }, [filterValues, sortPaginationValues, needRefresh])

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}

    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
    setDefaultFilterValues(filterValues)
  }, [location.search])

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    setLoading(true)
    fetchUsers(params)
  }, [filterValues, sortPaginationValues, needRefresh])

  return (
    <div className="p-4 rounded-sm">
      <div className="flex py-1 items-center justify-between">
        <div className="flex space-x-4 items-center">
          <Title level={2} className="m-0">
            Users
          </Title>
          <Button
            onClick={() => handleCreateUser()}
            type="primary"
            className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
          >
            Add New User
          </Button>
        </div>
      </div>

      <div className="flex justify-between">
        <Tabs
          defaultActiveKey="all"
          activeKey={filterUser}
          onChange={handleTabChange}
          items={[
            { label: `All (${totalUsers})`, key: "all" },
            { label: `Administrator (${adminCount})`, key: "admin" },
          ]}
        />
      </div>

      <div className=" items-center">
        <Space className=" space-x-5 flex justify-between">
          <Space className="space-x-3">
            <Select
              defaultValue="Bulk Action"
              style={{ width: 120 }}
              options={[{ value: "all", label: "Bulk Action" }]}
            />
            <Button>Apply</Button>
          </Space>
          <Space>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
          </Space>
        </Space>
      </div>
      <Card type="inner" style={styles.card}>
        <Filters
          filterRef={filterRef}
          defaultFilterValues={defaultFilterValues}
          onSubmit={onSearch}
          filters={
            [
              {
                label: "Username",
                placeholder: "Find Username",
                name: "id",
                type: "select-search",
                options: userNameFill,
              },
              {
                label: "Email",
                placeholder: "Find Email",
                name: "email",
                type: "select-search",
                options: emailOptions,
              },
            ] as FilterComponent[]
          }
        />
      </Card>
      <Table
        loading={isLoading}
        className="mt-3 border border-gray-300 custom-table"
        rowSelection={{}}
        columns={columns}
        dataSource={filterUser === "all" ? dataSource : dataSource.filter(data => data.role.id === 1)}
        pagination={{ pageSize: 15 }}
        scroll={{ y: 400 }}
        rowKey={record => record.id}
      />
    </div>
  )
}

export default UsersListing

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
