import type { FormProps } from "antd";
import { Form, message } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router";
import { FieldType } from "~/types/types";
import { Paths } from "~/constants";
import Publish from "./Publish";
import PageForm from "./PageForm";
import { slugPattern } from "~/utils/validationPatterns";
import pageService from "../services/page";


const AddNewPage = () => {
  const [quillValue, setQuillValue] = React.useState<string>("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { addPage, checkSlug } = pageService();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const slugValue = values.slug
      ? values.slug.replace(slugPattern, "")
      : values.name
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "");

    const isSlugUnique = async (slug: string): Promise<boolean> => {
      try {
        const response = await checkSlug(slug);
        return response.data.length === 0;
      } catch {
        message.error("Error checking slug. Please try again.");
        return false;
      }
    };

    const isUnique = await isSlugUnique(slugValue || "");
    if (!isUnique) {
      message.error(
        values.slug
          ? "Slug already exists. Please choose another slug."
          : "Slug generated from name already exists. Please choose another name or enter another slug."
      );
      return;
    }

    const dataPayload = {
      name: values.name,
      slug: slugValue,
      content: quillValue,
    };

    try {
      await addPage({ data: dataPayload });
      message.success("Page added successfully!");
      form.resetFields();
      navigate(Paths.PAGES.LIST.PATH);
    } catch {
      message.error("Failed to add page. Please try again.");
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {};

  return (
    <div className="p-4">
      <Title level={2} className="m-0">
        Add New Page
      </Title>
      <div className="flex my-5 space-x-8">
        <PageForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          quillValue={quillValue}
          setQuillValue={setQuillValue}
        />
        <div className="space-y-4 w-[17%]">
          {/* <Publish onSubmit={form.submit} isCreateEdit={true} /> */}
        </div>
      </div>
    </div>
  );
};

export default AddNewPage;
