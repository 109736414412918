// import { message } from "antd"
import axios from "axios"

export const getAllPages = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/pages`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching pages:", error)
    throw error
  }
}

export const getAllPosts = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/posts`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching posts:", error)
    throw error
  }
}

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/cateogries`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching category:", error)
    throw error
  }
}

export const getAllProducts = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/products`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching product product:", error)
    throw error
  }
}

export const getAllTags = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/tags`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching tag:", error)
    throw error
  }
}

export const getAllMenuitems = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/menu-items`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching menu-items:", error)
    throw error
  }
}

export const getAllMenuPreference = async () => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/api/menu-preferences`, {
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_API_TOKEN}`,
      },
    })
    return response.data
  } catch (error) {
    console.error("Error fetching MenuPreference:", error)
    throw error
  }
}



const menuService = {
  getAllPages,
  getAllPosts,
  getAllCategories,
  getAllProducts,
  getAllTags,
  getAllMenuitems,
  getAllMenuPreference,
}

export default menuService
