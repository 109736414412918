import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons"

import { doLogoutAction } from "~/modules/auth/services/redux/auth/login.slice"
import { useAppDispatch } from "~/modules/auth/services/redux/hook"
import { Button, Layout, Menu, notification } from "antd"
import { Header } from "antd/es/layout/layout"
import { FC, useEffect, useRef, useState } from "react"
import { Outlet } from "react-router-dom"
import { Breadcrumbs } from "../Breadcrumbs"
import { LayoutSider } from "./Sider"

const { Content, Footer } = Layout

export const MainLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [isUserMenuOpen, setUserMenuOpen] = useState(false)
  const userMenuRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useAppDispatch()

  const handleUserMenuClick = () => {
    setUserMenuOpen(prev => !prev)
  }

  const handleLogout = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("user")
    dispatch(doLogoutAction())
    notification.success({
      message: "Logout successful",
      description: "You have successfully logged out.",
      placement: "topRight",
    })
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
      setUserMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <Layout className="layout" id="mtb-erp-app">
      <LayoutSider collapsed={collapsed} />
      <Layout>
        <Header className="bg-white p-0 flex justify-between">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            className="text-base w-[64px] h-[64px] p-3"
          />
          <div className="relative flex items-center pr-[30px]" ref={userMenuRef}>
            <img
              className="w-8 h-8 rounded-full border-4 border-indigo-400 cursor-pointer"
              src="https://randomuser.me/api/portraits/women/50.jpg"
              alt="User Avatar"
              onClick={handleUserMenuClick}
            />
            {isUserMenuOpen && (
              <Menu
                mode="vertical"
                className="menu-dropdown z-50 absolute right-0 top-full mt-2 shadow-md"
                onClick={handleUserMenuClick}
              >
                <Menu.Item key="profile">Profile</Menu.Item>
                <Menu.Item key="logout" onClick={handleLogout}>
                  Logout
                </Menu.Item>
              </Menu>
            )}
          </div>
        </Header>
        <Content style={styles.content}>
          <Breadcrumbs />
          <Layout className="site-layout-background" style={styles.layout}>
            <Content style={{ padding: "0 12px" }}>
              <Outlet />
            </Content>
          </Layout>
        </Content>
        <Footer style={{ textAlign: "center" }}>Copyright ©2024 - Dan Solutions Co., Ltd</Footer>
      </Layout>
    </Layout>
  )
}

const styles = {
  content: { padding: "0 32px", minHeight: "calc(100vh - 134px)" },
  layout: { padding: "12px 0" },
}

