import { Form, Input, Flex, Button, message, Typography } from "antd"
import { createCategory } from "../services/category"
import { PlusCircleOutlined } from "@ant-design/icons"
import Title from "antd/es/typography/Title"

interface FormAddCategoryProps {
  onCategoryAdded: () => void
}

const FormAddCategory: React.FC<FormAddCategoryProps> = ({ onCategoryAdded }) => {
  const [form] = Form.useForm()
  const handleSubmit = async () => {
    try {
      const values = form.getFieldsValue()
      await createCategory(values)

      onCategoryAdded()
      form.resetFields()
      message.success("Category added successfully")
    } catch {
      message.error("Failed to add category")
    }
  }

  return (
    <div className="w-[35%] float-left">
      <Title level={4} className="mb-6">
        Add New Category
      </Title>
      <Form labelAlign="left" labelCol={{ span: 5 }} form={form} onFinish={handleSubmit} className="max-w-600 mt-5">
        <Flex gap="small" vertical align="flex-start" justify="space-between">
          <Form.Item
            className="w-full"
            name="name"
            label={<span className="text-base font-medium">Name</span>}
            rules={[{ required: true, message: "Tối thiểu 10 ký tự!", min: 10 }]}
            extra="The name is how it appears on your site."
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="slug"
            label={<span className="text-base font-medium">Slug</span>}
            rules={[{ required: true, message: "Không được để trống!" }]}
            extra="The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens."
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label={<span className="text-base font-medium">Description</span>}
            rules={[{ required: true, message: "Không được để trống!" }]}
            extra="The description is not prominent by default; however, some themes may show it."
          >
            <Input.TextArea rows={6} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 1 }}>
            <Button icon={<PlusCircleOutlined />} type="primary" htmlType="submit">
              Add New Category
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </div>
  )
}

export default FormAddCategory
