import type { FormProps } from "antd"
import { Button, Form, message, Space } from "antd"
import Title from "antd/es/typography/Title"
import React, { useCallback, useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useNavigate, useParams } from "react-router"
import { Paths } from "~/constants"
import { CategoryOption, FieldType, MediaData, PostData, TagOption } from "~/types/types"
import { checkSlug, fetchPostId, updatePost } from "../services/post"
import Categories from "./Categories"
import FeaturedImage from "./FeaturedImage"
import PostForm from "./PostForm"
import Publish from "./Publish"
import Tags from "./Tags"

const EditPost = () => {
  const [quillValue, setQuillValue] = React.useState<string>("")
  const [post, setPost] = useState<PostData | null>(null)
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [status, setStatus] = useState("Draft")
  const [publishDate, setPublishDate] = useState<string | null>(null)

  const [selectedImage, setSelectedImage] = useState<MediaData | null>(null)
  const [selectedMetaImage, setSelectedMetaImage] = useState<MediaData | null>(null)
  const [form] = Form.useForm()
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()

  const onFinish: FormProps<FieldType>["onFinish"] = async values => {
    const isSlugChanged = values.slug !== post?.attributes?.slug
    if (isSlugChanged) {
      const isSlugUnique = async (slug: string): Promise<boolean> => {
        try {
          const response = await checkSlug(slug)
          return response.data.length === 0
        } catch {
          return false
        }
      }

      const isUnique = await isSlugUnique(values.slug || "")

      if (!isUnique) {
        message.error("Slug already exists. Please choose another slug.")
        return
      }
    }
    const dataPayload = {
      name: values.name,
      content: quillValue,
      excerpt: values.excerpt,
      categories: selectedCategories || [],
      tags: selectedTags || [],
      image: selectedImage || null,
      ...(isSlugChanged && { slug: values.slug }),
      publishedAt: status === "Draft" ? null : new Date().toISOString(),
      SEO: {
        metaTitle: values.metaTitle,
        metaDescription: values.metaDescription,
        keywords: values.keywords,
        metaRobots: values.metaRobots,
        metaViewport: values.metaViewport,
        structuredData: typeof values.structuredData === "string" ? JSON.parse(values.structuredData) : {},
      },
    }

    try {
      await updatePost(Number(id), { data: dataPayload })
      message.success("Post update successfully!")

      form.resetFields()

      navigate(Paths.POSTS.LIST.PATH)
    } catch {
      message.error("Failed to update post. Please try again.")
    }
  }

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {}

  const handleFetchPost = useCallback(
    async (id: number) => {
      try {
        const response = await fetchPostId(id)
        setPost(response)
        form.setFieldsValue(response.attributes)
        form.setFieldValue("metaTitle", response.attributes.SEO.metaTitle)
        form.setFieldValue("metaDescription", response.attributes.SEO.metaDescription)
        form.setFieldValue("keywords", response.attributes.SEO.keywords)
        form.setFieldValue("metaRobots", response.attributes.SEO.metaRobots)
        form.setFieldValue("metaViewport", response.attributes.SEO.metaViewport)
        form.setFieldValue("structuredData", JSON.stringify(response.attributes.SEO.structuredData))

        setSelectedCategories(response.attributes.categories.data.map((category: CategoryOption) => category.id))
        setSelectedTags(response.attributes.tags.data.map((tag: TagOption) => tag.id))
        setQuillValue(response.attributes.content)
        const image = response.attributes.image?.data
        setSelectedImage(image)
        const metaImage = {
          id: response.attributes.SEO.metaImage?.data.id ?? 0,
          ...response.attributes.SEO.metaImage?.data.attributes,
        }
        setSelectedMetaImage(metaImage)
      } catch {
        message.error("Error fetching post")
      }
    },
    [form],
  )

  const onChangeCategories = (checkedValues: string[]) => {
    setSelectedCategories(checkedValues)
  }
  const onChangeTags = (checkedValues: string[]) => {
    setSelectedTags(checkedValues)
  }

  const onChangeImage = (image: MediaData | null) => {
    setSelectedImage(image)
  }

  const onChangeMetaImage = (image: MediaData | null) => {
    setSelectedMetaImage(image)
  }

  useEffect(() => {
    if (id) {
      handleFetchPost(Number(id))
    }
  }, [id, handleFetchPost])

  return (
    <div className="p-4">
      <Space>
        <Title level={2} className="">
          Edit Post
        </Title>
        <Button
          onClick={() => navigate(Paths.POSTS.CREATE.PATH)}
          className="bg-white  text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Post
        </Button>
      </Space>
      <div className="flex justify-between">
        <PostForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          quillValue={quillValue}
          setQuillValue={setQuillValue}
          onChangeMetaImage={onChangeMetaImage}
        />
        <div className=" w-[22%] pt-2">
          <Publish
            onSubmit={form.submit}
            isCreateEdit={false}
            setStatus={setStatus}
            status={status}
            setPublishDate={setPublishDate}
          />
          <Categories selectedCategories={selectedCategories} onChangeCategories={onChangeCategories} />
          <Tags selectedTags={selectedTags} onChangeTags={onChangeTags} />
          <FeaturedImage selectedMedia={selectedImage} setSelectedMedia={onChangeImage} />
        </div>
      </div>
    </div>
  )
}

export default EditPost
