import { HttpClient } from "~/api/HttpClient"
import { AttributesCategory } from "~/types/types"
import { AxiosRequestConfig } from "axios"

export const API_PREFIX_PATH = "/api/cateogries"
export const fetchCategories = async (page: number, pageSize: number, searchTerm?: string) => {
  const params: AxiosRequestConfig["params"] = {
    populate: "*",
    sort: "createdAt:desc",
    pagination: {
      page,
      pageSize,
    },
    filters: {
      name: { $containsi: searchTerm },
    },
  }
  return await HttpClient.get(`${API_PREFIX_PATH}`, {
    params,
  })
}

export const updateCategory = async (id: string, updatedData: AttributesCategory) => {
  return await HttpClient.put(`${API_PREFIX_PATH}/${id}`, { data: updatedData })
}
export const createCategory = async (data: AttributesCategory) => {
  return await HttpClient.post(`${API_PREFIX_PATH}`, { data })
}

export const deleteCategory = async (id: number) => {
  return await HttpClient.delete(`${API_PREFIX_PATH}/${id}`)
}

export const getCategoryById = async (id: number) => {
  return await HttpClient.get(`${API_PREFIX_PATH}/${id}`)
}

export const fetchTags = async () => {
  return await HttpClient.get(`/api/tags`)
}
