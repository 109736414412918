import type { FormProps } from "antd"
import { Form, message } from "antd"
import Title from "antd/es/typography/Title"
import React from "react"
import { useNavigate } from "react-router"
import { addContactForm, checkSlug } from "../services/contactForm"
import { ContactFormData, FieldTypeContactForm } from "~/types/types"
import { Paths } from "~/constants"
import { slugPattern } from "~/utils/validationPatterns"
import Publish from "./Publish"
import FormContact from "./FormContact"

const AddNewContactForm = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const onFinish: FormProps<FieldTypeContactForm>["onFinish"] = async values => {
    const slugValue = values.slug
      ? values.slug.replace(slugPattern, "")
      : values.nameContact
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "")

    const isSlugUnique = async (slug: string): Promise<boolean> => {
      try {
        const response = await checkSlug(slug)
        return response.data.length === 0
      } catch {
        return false
      }
    }

    const isUnique = await isSlugUnique(slugValue || "")
    if (!isUnique) {
      if (values.slug) {
        message.error("Slug already exists. Please choose another slug.")
      } else {
        message.error("Slug generated from name already exists. Please choose another name or enter another slug.")
      }
      return
    }

    const dataPayload : ContactFormData = {
      nameContact: values.nameContact,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      numberPhone: values.numberPhone,
      slug: slugValue,
      message: values.message
    }

    try {
      await addContactForm({ data: dataPayload })
      message.success("Contact form added successfully!")

      form.resetFields()

      navigate(Paths.CONTACTFORM.LIST.PATH)
    } catch {
      message.error("Failed to add contact form. Please try again.")
    }
  }

  const onFinishFailed: FormProps<FieldTypeContactForm>["onFinishFailed"] = () => {}

  return (
    <div className="p-4">
      <Title level={2} className="m-0">
        Add New Contact Form
      </Title>
      <div className="flex my-5 space-x-8">
        <FormContact
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
        <div className="space-y-4 w-[17%]">
          {/* <Publish onSubmit={form.submit} isCreateEdit={true} /> */}
        </div>
      </div>
    </div>
  )
}

export default AddNewContactForm
