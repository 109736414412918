import { HttpClient } from "~/api/HttpClient";
import { AxiosRequestConfig } from "axios";
import {  ContactFormData } from "~/types/types";

export const API_PREFIX_CONTACTFORM_PATH = "/api/contact-forms"

export const fetchAllContactForm = async () => {
    return await HttpClient.get(`${API_PREFIX_CONTACTFORM_PATH}`)
}

export const fetchContactForm = async (page: number, pageSize: number, searchTerm?: string) => {
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
      pagination: {
        page,
        pageSize,
      },
      sort: ['createdAt:desc'],
      filters: {
        nameContact: { $containsi: searchTerm },
      },
      
    };
  
    return await HttpClient.get(`${API_PREFIX_CONTACTFORM_PATH}`, { params });
  };
  
  export const fetchContactFormId = async (id: number) => {
    return await HttpClient.get(`${API_PREFIX_CONTACTFORM_PATH}/${id}`);
  };

export const deleteContactForm = async (id : number) =>{
    const response = await HttpClient.delete(`${API_PREFIX_CONTACTFORM_PATH}/${id}`)
    return response.data
}

export const updateContactForm = async (id : number, data: {data: ContactFormData}) =>{
    return await HttpClient.put(`${API_PREFIX_CONTACTFORM_PATH}/${id}`,data)
}
export const addContactForm = async (data : {data: ContactFormData}) =>{
    return await HttpClient.post(`${API_PREFIX_CONTACTFORM_PATH}`, data)
}
export const checkSlug = async (slug: string) => {
    const params: AxiosRequestConfig["params"] = {
      filters: {
        slug: { $eq: slug },
      },
    }
    return await HttpClient.get(`${API_PREFIX_CONTACTFORM_PATH}`, { params })
  }