import { HttpClient } from "~/api/HttpClient"
import {  AxiosRequestConfig } from "axios"
import axios, { AxiosHeaders } from 'axios';
export const API_PREFIX_SEOSETTING_PATH = "/api/seo-setting"
import { SEOAttributes } from "~/types/types"
import { configs } from "~/configs"
import { message } from "antd"
export const fetchSeoSetting = async () => {
  const params: AxiosRequestConfig["params"] = {
    populate: "*",
  }
  return await HttpClient.get(`${API_PREFIX_SEOSETTING_PATH}`, { params })
}

export const updateSeoSetting = async (updatedData: Partial<SEOAttributes>) => {
  try {
    const response = await HttpClient.put(`${API_PREFIX_SEOSETTING_PATH}?populate=*`, {
      data: {
        metaTitle: updatedData.metaTitle,
        metaDescription: updatedData.metaDescription,
        metaKeywords: updatedData.metaKeywords,
        MetaImage: updatedData.MetaImage,
      },
    })
    return response.data
  } catch (error) {
    message.error("Error updating SEO setting:")
    throw error
  }
}

export const deleteFile = async (id: number) => {
  try {
    const response = await HttpClient.delete(`/api/upload/${id}`);
    return response[0];
  } catch (error) {
    message.error("Error deleting image:");
    throw error;
  }
}
export const uploadFile = async (data:FormData) => {
  try {
    const response = await HttpClient.post(`/api/upload`, data);
    return response[0];
  } catch (error) {
    message.error("Error uploading image:");
    throw error;
  }
}
