import type { FormProps } from "antd";
import { Button, Form, message, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router";
import Publish from "./Publish";
import { FieldType, PageData } from "~/types/types";
import PageForm from "./PageForm";
import { Paths } from "~/constants";
import { dataPayloadPage } from "~/types/types";
import pageService from "../services/page";


const EditPage = () => {
  const [quillValue, setQuillValue] = React.useState<string>("");
  const [Page, setPage] = useState<PageData | null>(null);
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { checkSlug, fetchPageById, updatePage } = pageService();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const isSlugChanged = values.slug !== Page?.attributes?.slug;
    if (isSlugChanged) {
      const isSlugUnique = async (slug: string): Promise<boolean> => {
        try {
          const response = await checkSlug(slug);
          return response.data.length === 0;
        } catch {
          message.error("Error checking slug. Please try again.");
          return false;
        }
      };

      const isUnique = await isSlugUnique(values.slug || "");
      if (!isUnique) {
        message.error("Slug already exists. Please choose another slug.");
        return;
      }
    }
    
    const dataPayloadPage: dataPayloadPage = {
      name: values.name,
      content: quillValue,
      ...(isSlugChanged && { slug: values.slug }),
    };

    try {
      await updatePage(Number(id), { data: dataPayloadPage });
      message.success("Page updated successfully!");
      form.resetFields();
      navigate(Paths.PAGES.LIST.PATH);
    } catch {
      message.error("Failed to update page. Please try again.");
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = () => {};

  const handleFetchPage = useCallback(
    async (id: number) => {
      try {
        const response = await fetchPageById(id);
        setPage(response);
        form.setFieldsValue(response.attributes);
        setQuillValue(response.attributes.content);
      } catch {
        message.error("Error fetching page");
      }
    },
    [form]
  );

  useEffect(() => {
    if (id) {
      handleFetchPage(Number(id));
    }
  }, [id, handleFetchPage]);

  return (
    <div className="p-4">
      <Space>
        <Title level={2} className="m-0">
          Edit Page
        </Title>
        <Button
          onClick={() => navigate(Paths.PAGES.CREATE.PATH)}
          className="bg-white mb-2 text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Page
        </Button>
      </Space>
      <div className="flex my-2 space-x-8">
        <PageForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          quillValue={quillValue}
          setQuillValue={setQuillValue}
        />
        <div className="space-y-4 w-[17%]">
          {/* <Publish onSubmit={form.submit} isCreateEdit={false} /> */}
        </div>
      </div>
    </div>
  );
};

export default EditPage;
