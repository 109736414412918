import React, { useEffect, useState } from "react"
import { Button, Input, Table, Select, Space, Popconfirm, Tabs, Typography, message } from "antd"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { DataSettingItem } from "~/types/types"
import { Paths, SETTINGS } from "~/constants"
import { useNavigate } from "react-router"
import useSettingService from "../service/settingService"

function SettingsListing() {
  const navigate = useNavigate()
  const { Title } = Typography
  const [totalKey, setTotalKey] = useState(0)
  const [dataSource, setDataSource] = useState<DataSettingItem[]>([])
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>("")

  const settingsServices = useSettingService()

  const fetchSettings = async (searchTerm: string = "") => {
    try {
      const response = await settingsServices.getAllSettings(searchTerm)

      if (response && response.data) {
        const formattedData = response.data.map((DataSettingItem: DataSettingItem) => ({
          id: DataSettingItem.id,
          key: DataSettingItem.attributes.key,
          value: DataSettingItem.attributes.value,
          description: DataSettingItem.attributes.description,
        }))
        setDataSource(formattedData)
        const keyCount = response.meta?.pagination?.total || formattedData.length
        setTotalKey(keyCount)
      } else {
        message.error("Failed to fetch settings")
      }
    } catch {
      message.error("An error occurred while fetching settings")
    }
  }

  const handleSearch = () => {
    fetchSettings(searchTerm)
  }

  const handleCreateSetting = () => {
    const createPath = SETTINGS.CREATE.PATH
    navigate(createPath)
    fetchSettings()
  }

  const handleEditSetting = (id: number) => {
    navigate(`${Paths.SETTINGS.LIST.PATH}/${id}`)
    fetchSettings()
  }

  const handleDeleteSetting = async (id: number) => {
    try {
      const success = await settingsServices.deleteSetting(id)
      if (success) {
        setNeedRefresh(!needRefresh)
      }
    } catch {
      message.error("An error occurred while deleting setting")
    }
  }

  useEffect(() => {
    fetchSettings()
  }, [needRefresh])

  const columns = [
    {
      title: <span>ID</span>,
      dataIndex: "id",
      key: "id",
      showSorterTooltip: false,
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id: number) => (
        <>
          <Space size="middle" className="justify-center items-center">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => {
                handleEditSetting(id)
              }}
              className="bg-blue-500 px-4 py-2 rounded"
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this setting?"
              okText="Yes"
              onConfirm={() => handleDeleteSetting(id)}
              cancelText="Cancel"
            >
              <Button danger type="primary" icon={<DeleteOutlined />}>
                <span className="">Delete</span>
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ]

  return (
    <div className="p-4 rounded-sm">
      <div className="flex py-1 items-center justify-between">
        <div className="flex space-x-4 items-center">
          <Title level={2} className="m-0">
            Settings
          </Title>
          <Button
            onClick={() => handleCreateSetting()}
            type="primary"
            className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
          >
            Add New Settings
          </Button>
        </div>
      </div>

      <div className="flex justify-between">
        <Tabs defaultActiveKey="all" items={[{ label: `All (${totalKey})`, key: "all" }]} />
        <div className="flex items-center mb-[20px] ">
          <Space className="space-x ">
            <Input value={searchTerm} onChange={e => setSearchTerm(e.target.value)} onPressEnter={handleSearch} />
            <Button className="border-[1px] outline-none px-3 py-1 duration-300" onClick={handleSearch}>
              Search Settings
            </Button>
          </Space>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <Space className=" space-x-5">
          <Space className="space-x-3">
            <Select
              defaultValue="Bulk Action"
              style={{ width: 120 }}
              options={[{ value: "all", label: "Bulk Action" }]}
            />
            <Button>Apply</Button>
          </Space>
          <Space className="flex space-x-3 items-center">
            <Select
              defaultValue="All key"
              style={{ width: 120 }}
              options={[
                { value: "1", label: "Key 1" },
                { value: "2", label: "Key 2" },
                { value: "2", label: "Key 3" },
              ]}
            />
            <Button>Filter</Button>
          </Space>
        </Space>
      </div>

      <Table
        className="mt-3 border border-gray-300 custom-table"
        rowSelection={{}}
        columns={columns}
        dataSource={dataSource}
        pagination={{ pageSize: 15 }}
        scroll={{ y: 400 }}
      />
    </div>
  )
}

export default SettingsListing
