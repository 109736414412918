import { Outlet } from "react-router-dom"
import React from "react"

const Auth = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Auth
