import Auth from "~/modules/auth/Auth"
import Login from "~/modules/auth/login/Login"
import { CategoryRouter } from "~/modules/category"
import { ContactRouter } from "~/modules/contact-form"
import { HomeRouter } from "~/modules/home"
import { MediaRouter } from "~/modules/media"
import { SeoSettingRouter } from "~/modules/seoSetting"
import { SettingRouter } from "~/modules/settings/Router"
import { UserRouter } from "~/modules/users/Router"
import ProtectedRoute from "~/utils/ProtectedRoutes"
import RequireAuth from "~/utils/RejectedRoutes"
import { Navigate, Route, Routes } from "react-router-dom"
import history from "."
import { MainLayout } from "../components/layout"
import { Paths } from "../constants"
import { PageRouter } from "../modules/page/index"
import { PostRouter } from "../modules/post"
import { HistoryRouter } from "./HistoryRouter"
import { MenuRouter } from "~/modules/menu/Router"

export const Navigator = () => {
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          element={
            <RequireAuth>
              <Auth />
            </RequireAuth>
          }
        >
          <Route path={Paths.PUBLIC.LOGIN.PATH} element={<Login />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to={Paths.DASHBOARD.DETAIL.PATH} replace />} />
          <Route path={Paths.DASHBOARD.DETAIL.PATH} element={<HomeRouter />} />
          <Route path={`${Paths.SETTINGS.LIST.PATH}/*`} element={<SettingRouter />} />
          <Route path={`${Paths.POSTS.LIST.PATH}/*`} element={<PostRouter />} />
          <Route path={`${Paths.MEDIA.LIST.PATH}/*`} element={<MediaRouter />} />
          <Route path={`${Paths.SEOSETTING.LIST.PATH}/*`} element={<SeoSettingRouter />} />
          <Route path={`${Paths.CONTACTFORM.LIST.PATH}/*`} element={<ContactRouter />} />
          <Route path={`${Paths.CATEGORIES.LIST.PATH}/*`} element={<CategoryRouter />} />
          <Route path={`${Paths.USERS.LIST.PATH}/*`} element={<UserRouter />} />
          <Route path={`${Paths.MENUS.LIST.PATH}/*`} element={<MenuRouter />} />
          <Route path={`${Paths.PAGES.LIST.PATH}/*`} element={<PageRouter />} />
        </Route>
      </Routes>
    </HistoryRouter>
  )
}
