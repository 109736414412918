import { Route, Routes } from "react-router-dom"
import List from "./pages/List"
import AddNewPost from "./pages/AddNewPost"
import EditPost from "./pages/EditPost"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/create" element={<AddNewPost />} />
      <Route path="/:id" element={<EditPost />} />
    </Routes>
  )
}
