import { InputSearchProps } from "~/types/types"
import { SearchOutlined } from "@ant-design/icons"
import { Button, Input, Space } from "antd"
import React from "react"

const SearchInput: React.FC<InputSearchProps> = ({ handleSearchChange }) => {
  return (
    <div>
      <Space className="space-x-3">
        <Input type="search" className="custom-input" onChange={handleSearchChange} />
        <Button className="border-[1px] outline-none px-3 py-1 duration-300" icon={<SearchOutlined />}>
          Search Media
        </Button>
      </Space>
    </div>
  )
}

export default SearchInput
