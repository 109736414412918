import type { FormProps } from "antd"
import { Button, Form, message, Space } from "antd"
import Title from "antd/es/typography/Title"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { checkSlug, fetchContactFormId, updateContactForm } from "../services/contactForm"
import { FieldTypeContactForm, ContactFormData } from "~/types/types"
import Publish from "./Publish"
import FormContact from "./FormContact"
import { Paths } from "~/constants"

const EditContact = () => {
  const [form] = Form.useForm()
  const [contact, setContact] = useState<ContactFormData | null>(null)
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const onFinish: FormProps<FieldTypeContactForm>["onFinish"] = async values => {
    if (!contact) {
      message.error("Contact data is not loaded.")
      return
    }

    const isSlugChanged = values.slug !== contact.slug

    if (isSlugChanged) {
      const isSlugUnique = async (slug: string): Promise<boolean> => {
        try {
          const response = await checkSlug(slug)
          return response.data.length === 0
        } catch (error) {
          console.error("Slug check error:", error)
          return false
        }
      }

      const isUnique = await isSlugUnique(values.slug || "")

      if (!isUnique) {
        message.error("Slug already exists. Please choose another slug.")
        return
      }
      if (values.slug === "") {
        values.slug = values.nameContact
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "")
      }
    }

    const dataPayload: ContactFormData = {
      nameContact: values.nameContact || "",
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      email: values.email || "",
      numberPhone: values.numberPhone || "",
      ...(isSlugChanged && { slug: values.slug || "" }),
      message: values.message || "",
    }

    try {
      await updateContactForm(Number(id), { data: dataPayload });
      message.success("Contact updated successfully!");
      form.resetFields();
      navigate(Paths.CONTACTFORM.LIST.PATH);
    } catch (error: any) {
      message.error(`Failed to update contact. Error: ${error?.message || "Please try again."}`);
    }
  }

  const onFinishFailed: FormProps<FieldTypeContactForm>["onFinishFailed"] = () => {}

  const handleFetchContact = useCallback(
    async (id: number) => {
      try {
        const response = await fetchContactFormId(id)
        const contactData = response.data.attributes
        setContact(contactData)
        form.setFieldsValue({
          nameContact: contactData.nameContact,
          firstName: contactData.firstName,
          lastName: contactData.lastName,
          email: contactData.email,
          numberPhone: contactData.numberPhone,
          slug: contactData.slug,
          message: contactData.message,
        })
      } catch (error) {
        console.error("Fetch error:", error)
        message.error("Error fetching contact")
      }
    },
    [form],
  )

  useEffect(() => {
    if (id) {
      handleFetchContact(Number(id))
    }
  }, [id, handleFetchContact])

  return (
    <div className="p-4">
      <Space>
        <Title level={2} className="m-0">
          Edit Contact
        </Title>
        <Button
          onClick={() => navigate(Paths.CONTACTFORM.CREATE.PATH)}
          className="bg-white mb-2 text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Contact
        </Button>
      </Space>
      <div className="flex my-2 space-x-8">
        <FormContact form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} />
        <div className="space-y-4 w-[17%]">
          {/* <Publish onSubmit={form.submit} isCreateEdit={false} /> */}
        </div>
      </div>
    </div>
  )
}

export default EditContact
