import "./App.css"
import { ConfigProvider } from "antd"
import { Navigator } from "./routers/Navigator"

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          paddingLG: 10,
          colorBgMask: "rgba(0, 0, 0, 0.07)",
          boxShadow: "none",
        },
        components: {
          Typography: {
            titleMarginBottom: "0",
          },
        },
      }}
    >
      <Navigator />
    </ConfigProvider>
  )
}

export default App
