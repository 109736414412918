import {
  CodeSandboxOutlined,
  FileImageTwoTone,
  FileOutlined,
  FolderOutlined,
  HomeOutlined,
  MailOutlined,
  SettingOutlined,
  SnippetsOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons"
import { ItemType } from "antd/es/menu/interface"
import React from "react"
import { Paths } from "../../constants"

export type MenuItem = ItemType & {
  path?: string
  permissionkey?: string
  parent?: string
}

export const settingItems: MenuItem[] = [
  {
    key: Paths.SETTINGS.LIST.PATH,
    label: Paths.SETTINGS.LIST.LABEL,
  },
  {
    key: Paths.SETTINGS.CREATE.PATH,
    label: Paths.SETTINGS.CREATE.LABEL,
  },
]
export const postItems: MenuItem[] = [
  {
    key: Paths.POSTS.LIST.PATH,
    label: Paths.POSTS.LIST.LABEL,
  },
  {
    key: Paths.POSTS.CREATE.PATH,
    label: Paths.POSTS.CREATE.LABEL,
  },
  {
    key: Paths.CATEGORIES.LIST.PATH,
    label: Paths.CATEGORIES.LIST.LABEL,
  },
]
export const menusItems: MenuItem[] = [
  {
    key: Paths.MENUS.LIST.PATH,
    label: Paths.MENUS.LIST.LABEL,
  },
  {
    key: Paths.MENUS.CREATE.PATH,
    label: Paths.MENUS.CREATE.LABEL,
  },
]
export const mediaItems: MenuItem[] = [
  {
    key: Paths.MEDIA.LIST.PATH,
    label: Paths.MEDIA.LIST.LABEL,
  },
  {
    key: Paths.MEDIA.CREATE.PATH,
    label: Paths.MEDIA.CREATE.LABEL,
  },
]
export const contactForm: MenuItem[] = [
  {
    key: Paths.CONTACTFORM.LIST.PATH,
    label: Paths.CONTACTFORM.LIST.LABEL,
  },
  {
    key: Paths.CONTACTFORM.CREATE.PATH,
    label: Paths.CONTACTFORM.CREATE.LABEL,
  },
]

export const usersItems: MenuItem[] = [
  {
    key: Paths.USERS.LIST.PATH,
    label: Paths.USERS.LIST.LABEL,
  },
  {
    key: Paths.USERS.CREATE.PATH,
    label: Paths.USERS.CREATE.LABEL,
  },
]

export const pageItems: MenuItem[] = [
  {
    key: Paths.PAGES.LIST.PATH,
    label: Paths.PAGES.LIST.LABEL,
  },
  {
    key: Paths.PAGES.CREATE.PATH,
    label: Paths.PAGES.CREATE.LABEL,
  },
]

export const menuItems: Array<ItemType & { parent?: string; icon?: React.ReactNode }> = [
  { label: "Dashboard ", key: "/", icon: React.createElement(HomeOutlined) }, // Sử dụng React.createElement
  { label: "Menu", key: "menus", icon: React.createElement(FileOutlined), children: menusItems },
  { label: "Post", key: "post", icon: React.createElement(FileOutlined), children: postItems },
  { label: "Page", key: "pages", icon: React.createElement(SnippetsOutlined), children: pageItems },
  { label: "Users", key: "users", children: usersItems, icon: React.createElement(UserDeleteOutlined) },
  { label: "Contact Form", key: "contactform", icon: <MailOutlined />, children: contactForm },
  { label: "Settings", key: "settings", icon: React.createElement(SettingOutlined), children: settingItems },
  { label: "Media", key: "upload", icon: <FileImageTwoTone />, children: mediaItems },
  { label: "SEO Setting", key: "seo-setting", icon: <CodeSandboxOutlined /> },
]
