import React, { useEffect, useState } from "react"
import { Button, Form, Input, message, Spin, Image, Modal, Flex } from "antd"
import { fetchMediaId, updateMedia, deleteMedia } from "../services/apiServices"
import { useParams, useNavigate } from "react-router"
import { configs } from "~/configs"
import { MediaData, FileUpdate } from "~/types/types"
import { Paths } from "~/constants"
import ImageEditor from "./ImageEditor"
import Title from "antd/es/typography/Title"

const EditMedia: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [mediaData, setMediaData] = useState<MediaData>()
  const [form] = Form.useForm()
  const [isEditingImage, setIsEditingImage] = useState(false)

  useEffect(() => {
    fetchMediaData()
  }, [id, isEditingImage])

  const fetchMediaData = async () => {
    setLoading(true)
    try {
      const media = await fetchMediaId(Number(id))
      setMediaData(media)
      form.setFieldsValue({
        name: media?.name ?? "",
        alternativeText: media?.alternativeText ?? "",
        caption: media?.caption ?? "",
      })
    } catch {
      message.error("Failed to fetch media data")
    } finally {
      setLoading(false)
    }
  }

  const handleFormSubmit = async (
    values: { title: string; alternativeText: string; caption: string; width?: number; height?: number },
    file?: File,
  ) => {
    setLoading(true)
    try {
      const updatedData = new FormData()
      const data: FileUpdate = {
        fileInfo: {
          name: values.title,
          alternativeText: values.alternativeText,
          caption: values.caption,
        },
        file: file,
      }
      updatedData.append("fileInfo", JSON.stringify(data.fileInfo))
      if (file) updatedData.append("files", file as Blob)

      await updateMedia(Number(id), updatedData)
      message.success("Media updated successfully")
      if (!isEditingImage) navigate(`${Paths.MEDIA.LIST.PATH}`)
      else {
        await fetchMediaData()
        setIsEditingImage(false)
      }
    } catch {
      message.error("Failed to update media")
    } finally {
      setLoading(false)
    }
  }
  const handleCopyUrl = () => {
    if (mediaData && mediaData.url) {
      navigator.clipboard
        .writeText(`${configs.API_URL}${mediaData.url}`)
        .then(() => message.success("URL copied to clipboard!"))
        .catch(() => message.error("Failed to copy URL"))
    }
  }

  const handleDownloadFile = (e: React.MouseEvent) => {
    e.preventDefault()
    if (mediaData) {
      fetch(`${configs.API_URL}${mediaData.url}`, {
        method: "GET",
        headers: {
          "Content-Type": mediaData.mime,
        },
      })
        .then(res => res.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = url
          link.setAttribute("download", mediaData.name)
          link.click()
        })
    }
  }

  const handleDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this media?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        setLoading(true)
        try {
          await deleteMedia(Number(id))
          message.success("Media deleted successfully")
          navigate(`${Paths.MEDIA.LIST.PATH}`)
        } catch {
          message.error("Failed to delete media")
        } finally {
          setLoading(false)
        }
      },
    })
  }

  return (
    <div className="ml-6">
      <div className="mt-5 flex">
        <Title className="mr-5" level={2}>
          Edit Media File
        </Title>
        <Button
          onClick={() => navigate(Paths.MEDIA.CREATE.PATH)}
          className="mt-1 bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Media File
        </Button>
      </div>
      <div className="edit-media-container mt-5" style={{ display: "flex", gap: "20px" }}>
        <div className="left-section" style={{ flex: 3 }}>
          {loading ? (
            <Spin />
          ) : (
            <>
              {isEditingImage ? (
                <div>
                  <ImageEditor
                    media={mediaData as MediaData}
                    isEditingImage={isEditingImage}
                    setIsEditingImage={setIsEditingImage}
                    handleFormSubmit={handleFormSubmit}
                    editDetail={true}
                  />
                </div>
              ) : (
                <div>
                  <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item label={<span className="text-base font-medium">Name</span>} name="name">
                      <Input className="mb-4 mt-2" placeholder="Enter name" />
                    </Form.Item>
                    <Form.Item name="image">
                      <div className="flex flex-col" style={{ alignItems: "flex-start" }}>
                        <Image src={`${configs.API_URL}${mediaData?.url}`} alt={mediaData?.name} height={350} />
                        <Button className="mt-4 mb-2" onClick={() => setIsEditingImage(true)}>
                          Edit Image
                        </Button>
                      </div>
                    </Form.Item>
                    <Form.Item
                      label={<span className="text-base font-medium">Alternative Text</span>}
                      name="alternativeText"
                    >
                      <Input className="mt-2 mb-2" placeholder="Enter alternative text" />
                    </Form.Item>
                    <Form.Item label={<span className="text-base font-medium">Caption</span>} name="caption">
                      <Input className="mt-2 mb-4" placeholder="Enter caption" />
                    </Form.Item>
                    <Button className="mr-3" type="primary" htmlType="submit">
                      Save Edits
                    </Button>
                    <Button onClick={() => navigate(`${Paths.MEDIA.LIST.PATH}`)}>Cancel Editing</Button>
                  </Form>
                </div>
              )}
            </>
          )}
        </div>

        <Flex className="right-section flex-1 flex-col bg-gray-50 pt-5 pl-5 h-[300px] ml-10">
          <div className="mb-5 border-b-2 pb-3">
            <p>
              <strong>Created at:</strong> {mediaData?.createdAt}
            </p>
            <p>
              <strong>Last updated:</strong> {mediaData?.updatedAt}
            </p>
            <p>
              <strong>File URL:</strong> <a href={mediaData?.url}>{mediaData?.url}</a>
            </p>
            <p>
              <strong>File size:</strong> {mediaData?.size}
            </p>
            <p>
              <strong>Dimensions:</strong> {mediaData?.height} x {mediaData?.width}
            </p>
          </div>

          <div className="">
            <Button className="mb-2 w-[200px]" onClick={handleCopyUrl}>
              Copy URL to Clipboard
            </Button>
            <br />
            <Button className="mb-2 w-[200px]" onClick={handleDownloadFile}>
              Download File
            </Button>
            <br />
            <Button className="w-[200px]" type="default" danger onClick={handleDelete}>
              Delete Permanently
            </Button>
          </div>
        </Flex>
      </div>
    </div>
  )
}

export default EditMedia
