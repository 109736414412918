import { Route, Routes } from "react-router-dom"
import SeoListing from "./pages/SeoListing"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<SeoListing />} />
    </Routes>
  )
}
