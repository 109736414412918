import React, { useState } from "react"
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons"
import { Button, Modal } from "antd"
import Link from "antd/es/typography/Link"
import Title from "antd/es/typography/Title"
import Media from "~/modules/media/pages/Media"
import AddNewMedia from "~/modules/media/pages/AddNewMedia"
import { DataImage, MediaData } from "~/types/types"
import { configs } from "~/configs"

interface FeaturedImageProps {
  selectedMedia: MediaData | null
  setSelectedMedia: (media: MediaData | null) => void
}

const FeaturedImage: React.FC<FeaturedImageProps> = ({ selectedMedia, setSelectedMedia }) => {
  const [isImageVisible, setImageVisible] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChooseMedia, setIsChooseMedia] = useState(true)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSelectMedia = (media: MediaData) => {
    console.log("featured Image", media)
    setSelectedMedia(media)
    setIsModalOpen(false)
  }
  return (
    <div className="categories border border-gray-300 rounded-[10px] mb-5">
      <div className="flex justify-between items-center px-4 py-2 border-b">
        <Title level={4} className="!m-0">
          Featured Image
        </Title>
        <Button
          onClick={() => setImageVisible(!isImageVisible)}
          className="flex items-center"
          icon={isImageVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
          type="text"
        ></Button>
      </div>
      {!isImageVisible || (
        <div className="m-4">
          {selectedMedia === null ? (
            <Link onClick={handleOpenModal} className="!underline text-base font-medium">
              Set Featured Image
            </Link>
          ) : (
            <div>
              <img
                onClick={handleOpenModal}
                src={
                  selectedMedia === null
                    ? ""
                    : selectedMedia.attributes === undefined
                    ? `${configs.API_URL}${selectedMedia.url}`
                    : `${configs.API_URL}${selectedMedia.attributes?.url}`
                }
                alt="featured"
                className="w-full"
              />
              <Link onClick={() => setSelectedMedia(null)} className="!underline text-base font-medium">
                Remove Featured Image
              </Link>
            </div>
          )}
        </div>
      )}
      <Modal
        open={isModalOpen}
        title={<span className="ml-4">Select Media</span>}
        onCancel={handleCloseModal}
        style={{ top: 20 }}
        width="95%"
        footer={null}
      >
        <div className="ml-4 mt-5">
          <Button
            onClick={() => setIsChooseMedia(true)}
            className="mr-2"
            style={{ backgroundColor: isChooseMedia ? "blue" : "initial", color: isChooseMedia ? "white" : "initial" }}
          >
            Select Media
          </Button>
          <Button
            onClick={() => setIsChooseMedia(false)}
            style={{
              backgroundColor: !isChooseMedia ? "blue" : "initial",
              color: !isChooseMedia ? "white" : "initial",
            }}
          >
            Upload Media
          </Button>
        </div>
        <div>
          {isChooseMedia ? (
            <Media isOpenModal={true} onSelectMedia={handleSelectMedia} />
          ) : (
            <AddNewMedia isOpenModal={true} setChooseMedia={setIsChooseMedia} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default FeaturedImage
