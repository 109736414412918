import React, { useEffect, useState } from "react"
import { Button, Checkbox, Input, List, message, Typography } from "antd"
import { CaretDownOutlined, CaretUpOutlined, PlusOutlined } from "@ant-design/icons"
import { fetchCategories } from "../services/post"
import { AttributesCategory, CategoriesProps, CategoryOption } from "~/types/types"
import { createCategory } from "~/modules/category/services/category"

const { Title, Link } = Typography

const Categories: React.FC<CategoriesProps> = ({ selectedCategories, onChangeCategories }) => {
  const [isCategoryListVisible, setCategoryListVisible] = useState(true)
  const [categories, setCategories] = useState<CategoryOption[] | null>([])
  const [isAddingCategory, setIsAddingCategory] = useState(false)
  const [newCategoryName, setNewCategoryName] = useState("")

  const handleFetchCategories = async () => {
    try {
      const response = await fetchCategories()
      const categoriesData = response.data
      if (Array.isArray(categoriesData)) {
        const categoryOptions = categoriesData.map(category => ({
          id: category.id,
          label: category.attributes.name,
          value: category.id,
          disabled: false,
        }))
        setCategories(categoryOptions)
      }
    } catch {
      message.error("Error fetching categories")
    }
  }

  const handleAddCategory = async () => {
    if (newCategoryName) {
      if (newCategoryName.length < 10) {
        return message.error("Name phải có ít nhất 10 ký tự")
      }
      try {
        const slug = newCategoryName
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "")
        const data: AttributesCategory = {
          name: newCategoryName,
          slug: slug,
          description: "",
        }

        const response = await createCategory(data)

        await handleFetchCategories()

        setNewCategoryName("")
        setIsAddingCategory(false)
        message.success(`Added new category: ${newCategoryName}`)
      } catch {
        message.error("Error adding category")
      }
    } else {
      message.error("Please enter a category name")
    }
  }

  useEffect(() => {
    handleFetchCategories()
  }, [])

  return (
    <div className="categories border border-gray-300 rounded-[10px] mb-5">
      <div className="flex justify-between items-center px-4 py-2 border-b">
        <Title level={4} className="!m-0">
          Categories
        </Title>
        <Button
          onClick={() => setCategoryListVisible(!isCategoryListVisible)}
          className="flex items-center"
          icon={isCategoryListVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
          type="text"
        ></Button>
      </div>

      {isCategoryListVisible && (
        <div className="px-4 py-2">
          <Checkbox.Group onChange={onChangeCategories} value={selectedCategories} className="w-[100%] pt-2">
            <List
              className="border-[1px] border-gray-300 w-[100%]"
              size="small"
              dataSource={categories ?? []}
              renderItem={item => (
                <List.Item>
                  <Checkbox key={item.value} value={item.value} className="ml-2">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>
          <div className="mt-4">
            <Link className="!underline text-base font-medium" onClick={() => setIsAddingCategory(!isAddingCategory)}>
              <PlusOutlined className="text-[10px]" /> Add New Categories
            </Link>
          </div>
          {isAddingCategory && (
            <div className="mt-4 flex">
              <Input
                placeholder="Enter category name"
                value={newCategoryName}
                onChange={e => setNewCategoryName(e.target.value)}
                style={{ marginRight: "8px" }}
              />
              <Button type="primary" onClick={handleAddCategory}>
                Add
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Categories
