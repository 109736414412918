import { HttpClient } from "~/api/HttpClient"
import { AxiosRequestConfig } from "axios"
import { message } from "antd"
import { useState } from "react"
import { dataPayloadPage } from "~/types/types"

const API_PREFIX_PAGES_PATH = "/api/pages"

const pageService = () => {
  const [isLoading, setIsLoading] = useState(false)
  
  const addPage = async (data: { data: dataPayloadPage }) => {
    setIsLoading(true)
    try {
      const response = await HttpClient.post(`${API_PREFIX_PAGES_PATH}`, data)
      message.success("Page added successfully!")
      return response
    } catch (error) {
      message.error("Failed to add page. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPageById = async (id: number) => {
    setIsLoading(true)

    if (!id) {
      message.error("Invalid ID.")
      setIsLoading(false)
      return null
    }

    try {
      const response = await HttpClient.get(`${API_PREFIX_PAGES_PATH}/${id}?populate=*`)
      return response?.data || null
    } catch (error) {
      message.error("Failed to fetch page data. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const updatePage = async (id: number, data: { data: dataPayloadPage }) => {
    setIsLoading(true)

    if (!id) {
      message.error("Invalid ID.")
      setIsLoading(false)
      return null
    }

    try {
      const response = await HttpClient.put(`${API_PREFIX_PAGES_PATH}/${id}`, data)
      message.success("Page updated successfully!")
      return response
    } catch (error) {
      message.error("Failed to update page. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const deletePage = async (id: number) => {
    setIsLoading(true)

    if (!id) {
      message.error("Invalid ID.")
      setIsLoading(false)
      return null
    }

    try {
      const response = await HttpClient.delete(`${API_PREFIX_PAGES_PATH}/${id}`)
      message.success("Page deleted successfully!")
      return response
    } catch (error) {
      message.error("Failed to delete page. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const fetchAllPages = async ( searchTerms: {[key: string]: string} = {}) => {
    setIsLoading(true)
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
      sort: "createdAt:desc",
      filters: {},
      
    }
    Object.keys(searchTerms).forEach(key => {
      const value = searchTerms[key];
      if (value) {
        params.filters[key] = { $containsi: value };
      }
    });
    try {
      const response = await HttpClient.get(`${API_PREFIX_PAGES_PATH}`, { params })
      return response || null
    } catch (error) {
      message.error("Failed to fetch pages. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const checkSlug = async (slug: string) => {
    const params: AxiosRequestConfig["params"] = {
      filters: {
        slug: { $eq: slug },
      },
    }

    try {
      const response = await HttpClient.get(`${API_PREFIX_PAGES_PATH}`, { params })
      return response || null
    } catch (error) {
      message.error("Failed to check slug. Please try again.")
      return null
    }
  }

  return {
    isLoading,
    addPage,
    fetchPageById,
    updatePage,
    deletePage,
    fetchAllPages,
    checkSlug,
  }
}

export default pageService

