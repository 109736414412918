import { CalendarOutlined, EyeOutlined, KeyOutlined } from "@ant-design/icons"
import { Button, Checkbox, CheckboxProps, Input, Radio, RadioChangeEvent, Select, Typography } from "antd"
import { useState } from "react"
import dayjs from "dayjs"

const { Text } = Typography

interface StatusPublishProps {
  setStatus: (status: string) => void
  status: string
}

export const StatusPublish = ({ setStatus, status }: StatusPublishProps) => {
  const [tempStatus, setTempStatus] = useState(status)
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleCancelClick = () => {
    setIsEditing(false)
    setTempStatus(status)
  }

  const handleChange = (value: any) => {
    setTempStatus(value.value)
  }

  const handleOkClick = () => {
    setStatus(tempStatus)
    setIsEditing(false)
  }

  return (
    <div>
      <Text className="text-sm">
        <KeyOutlined /> Status:
        <span className="ml-2">{status}</span>
        {!isEditing && (
          <span className="text-blue-500 underline cursor-pointer ml-2" onClick={handleEditClick}>
            Edit
          </span>
        )}
      </Text>

      {isEditing && (
        <div className="mt-2">
          <Select
            labelInValue
            defaultValue={{ value: status }}
            className="w-[100px]"
            onChange={handleChange}
            options={[
              { value: "Draft", label: "Draft" },
              { value: "Publish", label: "Publish" },
            ]}
          />
          <Button className="ml-2" type="primary" onClick={handleOkClick}>
            Ok
          </Button>
          <span className="text-blue-500 underline cursor-pointer ml-2" onClick={handleCancelClick}>
            Cancel
          </span>
        </div>
      )}
    </div>
  )
}

export const VisibilityPublish = () => {
  return (
    <div>
      <Text className="text-sm">
        <EyeOutlined /> Visibility:
        <span className="ml-2">Public</span>
        <a href="#" className="text-blue-500 underline cursor-pointer ml-2">
          View
        </a>
      </Text>
    </div>
  )
}

interface DatePublishProps {
  setPublishDate: (date: string) => void
}

export const DatePublish = ({ setPublishDate }: DatePublishProps) => {
  const now = dayjs()

  const [isEditing, setIsEditing] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [publishedDate, setPublishedDate] = useState<string | null>(null)

  const [tempDate, setTempDate] = useState({
    month: now.format("MMM"),
    day: now.format("DD"),
    year: now.format("YYYY"),
    hour: now.format("HH"),
    minute: now.format("mm"),
  })

  const handleEditClick = () => setIsEditing(true)
  const handleCancelClick = () => setIsEditing(false)

  const handleDateChange = (key: string, value: string) => {
    setTempDate(prev => ({ ...prev, [key]: value }))
    setPublishDate(value)
  }

  const handleOkClick = () => {
    const formattedDate = `${tempDate.month} ${tempDate.day}, ${tempDate.year} at ${tempDate.hour}:${tempDate.minute}`
    setPublishedDate(formattedDate)
    setIsEditing(false)
    setIsPublished(true)
  }

  return (
    <div>
      <Text className="text-sm ">
        <CalendarOutlined /> Publish:
        <span className="ml-2 text-xs">
          {isPublished
            ? publishedDate
            : new Date().toLocaleString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
        </span>
        {!isEditing && (
          <span className="text-blue-500 underline cursor-pointer ml-2" onClick={handleEditClick}>
            Edit
          </span>
        )}
      </Text>

      {isEditing && (
        <div className="mt-2 flex flex-col">
          <div className="flex items-center w-[300px]">
            <Select
              defaultValue={tempDate.month}
              className="w-[100px]"
              onChange={value => handleDateChange("month", value)}
              options={[
                { value: "Jan", label: "01-Jan" },
                { value: "Feb", label: "02-Feb" },
                { value: "Mar", label: "03-Mar" },
                { value: "Apr", label: "04-Apr" },
                { value: "May", label: "05-May" },
                { value: "Jun", label: "06-Jun" },
                { value: "Jul", label: "07-Jul" },
                { value: "Aug", label: "08-Aug" },
                { value: "Sep", label: "09-Sep" },
                { value: "Oct", label: "10-Oct" },
                { value: "Nov", label: "11-Nov" },
                { value: "Dec", label: "12-Dec" },
              ]}
            />
            <Input value={tempDate.day} onChange={e => handleDateChange("day", e.target.value)} className="w-[45px]" />
            ,
            <Input
              value={tempDate.year}
              onChange={e => handleDateChange("year", e.target.value)}
              className="w-[60px]"
            />
            at
            <Input
              value={tempDate.hour}
              onChange={e => handleDateChange("hour", e.target.value)}
              className="w-[45px]"
            />
            :
            <Input
              value={tempDate.minute}
              onChange={e => handleDateChange("minute", e.target.value)}
              className="w-[45px]"
            />
          </div>
          <div className="mt-2">
            <Button type="primary" onClick={handleOkClick}>
              Ok
            </Button>
            <span className="text-blue-500 underline cursor-pointer ml-2" onClick={handleCancelClick}>
              Cancel
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
