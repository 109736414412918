import { Route, Routes } from "react-router-dom"
import List from "../page/pages/List"
import AddNewPage from "../page/pages/AddNewPage"
import EditPage from "../page/pages/EditPage"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/create" element={<AddNewPage />} />
      <Route path="/edit/:id" element={<EditPage />} />
    </Routes>
  )
}
