export const formatDate = (createdAt: string | Date) => {
  const date = new Date(createdAt)
  const day = String(date.getUTCDate()).padStart(2, "0")
  const month = String(date.getUTCMonth() + 1).padStart(2, "0")
  const year = date.getUTCFullYear()
  const hours = String(date.getUTCHours()).padStart(2, "0")
  const minutes = String(date.getUTCMinutes()).padStart(2, "0")
  const seconds = String(date.getUTCSeconds()).padStart(2, "0")

  const formattedTime = `${hours}:${minutes}:${seconds}`
  const formattedDate = `${day}/${month}/${year}`
  return { formattedDate, formattedTime }
}
