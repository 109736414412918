import { Button, Form, Input, Select, Checkbox, message } from "antd"
import { useParams, useNavigate } from "react-router-dom"
import userService from "../services/userService"
import { useEffect, useState } from "react"

const UpsertForm = ({ editMode = false }: { editMode?: boolean }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const userServices = userService()
  const [roles, setRoles] = useState([])

  const getRoles = async () => {
    try {
      const response = await userServices.fetchAllRoles()
      setRoles(response.roles || [])
    } catch (error) {
      message.error("Failed to fetch roles.")
    }
  }

  const handleEmailBlur = async () => {
    const email = form.getFieldValue("email")
    if (email) {
      const emailExists = await userServices.checkEmailExists(email)
      if (emailExists.length > 0) {
        message.error("Email already exists.")
        form.setFields([
          {
            name: "email",
            errors: ["Email already exists"],
          },
        ])
      }
    }
  }

  const handleUsernameBlur = async () => {
    const userName = form.getFieldValue("username")

    if (userName) {
      const usernameExists = await userServices.checkUsernameExists(userName)

      if (usernameExists.length > 0) {
        message.error("Account name already exists.")
        form.setFields([
          {
            name: "username",
            errors: ["Account name already exists"],
          },
        ])
      }
    }
  }

  const handleFormSubmit = async (values: any) => {
    try {
      if (editMode) {
        await userServices.updateUser(Number(id), values)
        navigate("/users")
      } else {
        const res = await userServices.createUser(values)
        if (res?.status === 201) {
          message.success("The user has been added successfully.")
        }
      }
      form.resetFields()
    } catch (error) {
      console.error("Error during form submission:", error)
      message.error("An error occurred while submitting the form. Please try again.")
    }
  }

  const fetchUserData = async (userId: number) => {
    try {
      const userDataFetch = await userServices.getUserById(userId)

      if (userDataFetch) {
        form.setFieldsValue(userDataFetch)
        form.setFieldsValue({ role: userDataFetch.role.id })
        form.setFieldsValue({ language: userDataFetch.language || "en" })
      } else {
        message.warning("No user data found.")
      }
    } catch (error) {
      message.error("An error occurred while fetching user data.")
    }
  }

  useEffect(() => {
    if (editMode === false) {
      form.resetFields()
    }
  }, [editMode])

  useEffect(() => {
    if (id) {
      fetchUserData(Number(id))
    }
    getRoles()
  }, [id])

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 800 }}
        onFinish={handleFormSubmit}
        initialValues={!editMode ? { role: 3, language: "en", emailSubscription: true } : {}}
      >
        <Form.Item
          className="font-bold"
          label="Username"
          name="username"
          rules={[
            { required: true, message: "Account name cannot be left blank" },
            { pattern: /^[a-zA-Z0-9]+$/, message: "The account name cannot contain accents or special characters" },
            { min: 6, message: "Account name must be at least 6 characters" },
          ]}
        >
          <Input onBlur={handleUsernameBlur} autoComplete="current-username" />
        </Form.Item>

        <Form.Item
          className="font-bold"
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Email cannot be left blank" },
            { type: "email", message: "Email is not in correct format" },
          ]}
        >
          <Input onBlur={handleEmailBlur} disabled={editMode} />
        </Form.Item>

        <Form.Item
          className="font-bold"
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "FirstName cannot be left blank" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="font-bold" label="Last Name" name="lastName">
          <Input />
        </Form.Item>

        <Form.Item className="font-bold" label="Language" name="language">
          <Select
            options={[
              { value: "en", label: "English" },
              { value: "vn", label: "Vietnamese" },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item
          className="font-bold"
          label="Password"
          name="password"
          rules={[
            { required: !editMode, message: "Password cannot be left blank" },
            { min: 6, message: "Password must have at least 6 characters" },
          ]}
        >
          <Input.Password autoComplete="current-password" />
        </Form.Item>

        <Form.Item className="font-bold" label="Role" name="role">
          <Select
            options={roles.map((role: any) => ({
              value: role.id,
              label: role.name,
            }))}
          />
        </Form.Item>

        <Form.Item
          className="font-bold "
          label="Send User Notification"
          name="emailSubscription"
          valuePropName="checked"
        >
          <Checkbox className="ml-[30px] font-normal">Send the new user an email about their account</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editMode ? "Edit" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UpsertForm
