import { PathItem } from "~/types"

export const DASHBOARD: PathItem = {
  DETAIL: { PATH: "/", LABEL: "DashBoard", BREADCRUMB: ["DashBoard"] },
}

export const PUBLIC: PathItem = {
  LOGIN: { PATH: "/login", LABEL: "Đăng nhập", BREADCRUMB: ["Đăng nhập"] },
  FORGOT_PASSWORD: { PATH: "/forgot-password", LABEL: "", BREADCRUMB: [] },
  RESET_PASSWORD: { PATH: "/reset-password", LABEL: "", BREADCRUMB: [] },
}

export const SETTINGS: PathItem = {
  LIST: { PATH: "/settings", LABEL: "List settings", BREADCRUMB: ["List settings"] },
  CREATE: { PATH: "/settings/create", LABEL: "Create Setting", BREADCRUMB: ["Create New Setting"] },
  EDIT: { PATH: "/settings", LABEL: "", BREADCRUMB: [""] },
}

export const POSTS: PathItem = {
  LIST: { PATH: "/posts", LABEL: "All Post", BREADCRUMB: ["All Post"] },
  CREATE: { PATH: "/posts/create", LABEL: "Add New Post", BREADCRUMB: ["Add"] },
  EDIT: { PATH: `/posts`, LABEL: "Edit Post", BREADCRUMB: ["Edit"] },
}
export const SEOSETTING: PathItem = {
  LIST: { PATH: "/seo-setting", LABEL: "SEO", BREADCRUMB: ["SEO"] },
  EDiT: { PATH: "/seo-setting", LABEL: "Edit SEO Setting", BREADCRUMB: ["Edit"] },
}

export const MEDIA: PathItem = {
  LIST: { PATH: "/upload", LABEL: "Library", BREADCRUMB: ["Library"] },
  CREATE: { PATH: "/upload/create", LABEL: "Add New Media File", BREADCRUMB: ["Add New Media File"] },
  EDIT: { PATH: "/upload", LABEL: "", BREADCRUMB: [] },
}
export const CONTACTFORM: PathItem = {
  LIST: { PATH: "/contactForm", LABEL: "All Contact Form", BREADCRUMB: ["All ConTact Form"] },
  CREATE: { PATH: "/contactForm/create", LABEL: "Add New ContactForm", BREADCRUMB: ["Add"] },
  EDIT: { PATH: `/contactForm`, LABEL: "Edit Contact", BREADCRUMB: ["Edit"] },
}
export const CATEGORIES: PathItem = {
  LIST: { PATH: "/category", LABEL: "Category", BREADCRUMB: ["All Categories"] },
  EDIT: { PATH: `/category`, LABEL: "Edit Category", BREADCRUMB: ["Edit"] },
}
export const USERS: PathItem = {
  LIST: { PATH: "/users", LABEL: "All Users", BREADCRUMB: ["All Users"] },
  CREATE: { PATH: "/users/create", LABEL: "Create User", BREADCRUMB: ["Create New User"] },
  EDIT: { PATH: "/users/:idUser", LABEL: "", BREADCRUMB: ["Users Profile"] },
}
export const MENUS: PathItem = {
  LIST: { PATH: "/menus", LABEL: "All Menus", BREADCRUMB: ["All Menus"] },
  CREATE: { PATH: "/menus/create", LABEL: "Add New Menu", BREADCRUMB: ["Add"] },
  EDIT: { PATH: `/menus`, LABEL: "Edit Menu", BREADCRUMB: ["Edit"] },
}
export const PAGES: PathItem = {
  LIST: { PATH: "/pages", LABEL: "All Page", BREADCRUMB: ["PAGES"] },
  CREATE: { PATH: "/pages/create", LABEL: "Add New Page", BREADCRUMB: ["Add"] },
  EDIT: { PATH: "/pages/edit", LABEL: "Edit Page", BREADCRUMB: ["Edit"] },
}
