import axios, { AxiosHeaders, AxiosRequestHeaders } from "axios"
import { configs } from "../configs"
import { onHttpRequestError } from "./onHttpRequestError"

axios.defaults.baseURL = configs.API_URL
const token = `Bearer ${import.meta.env.VITE_API_TOKEN}`

export const getToken = () => {
  try {
    const token = localStorage.getItem("access_token")

    if (token) {
      return token
    }
    return null
  } catch (ex) {
    return null
  }
}

const withAuth = (headers: AxiosHeaders = new AxiosHeaders()): AxiosHeaders => {
  const token = getToken()
  if (token) {
    return headers.set("Authorization", `Bearer ${getToken()}`)
  } else {
    return headers.set(undefined)
  }
}

const catchTransactionError = async (asyncFunc: any, apiName = "") => {
  try {
    const response = await asyncFunc
    return response ? response.data : null
  } catch (error: any) {
    let response = {
      data: { data: null, error: null },
      message: error.message,
    }
    if (error.response) {
      response = { ...error.response, message: error.message }
    }
    onHttpRequestError(response, apiName)
  }
}

interface ConfigsProps {
  headers?: AxiosRequestHeaders
  apiName?: string
  params?: object
  data?: object
}

export const getRequest = async (url: string, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.get(url, {
      headers: { Authorization: token, ...configs.headers },
      ...configs,
    }),
    configs.apiName,
  )

export const postRequest = async (url: string, data: any = null, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.post(url, data, {
      headers: { Authorization: token, ...configs.headers },
      ...configs,
    }),
    configs.apiName,
  )

export const putRequest = async (url: string, data: any, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.put(url, data, {
      headers: { Authorization: token, ...configs.headers },
      ...configs,
    }),
    configs.apiName,
  )

export const patchRequest = async (url: string, data: any, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.patch(url, data, {
      headers: { Authorization: token, ...configs.headers },
      ...configs,
    }),
    configs.apiName,
  )

export const deleteRequest = async (url: string, configs: ConfigsProps = {}) =>
  catchTransactionError(
    axios.delete(url, {
      headers: { Authorization: token, ...configs.headers },
      ...configs,
    }),
    configs.apiName,
  )

export class HttpClient {
  static get = getRequest
  static post = postRequest
  static put = putRequest
  static patch = patchRequest
  static delete = deleteRequest
}
