import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import type { TableColumnsType, TablePaginationConfig, TableProps } from "antd"
import { Button, message, Modal, Space, Table } from "antd"
import { useCallback, useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import { deleteContactForm, fetchContactForm } from "../services/contactForm"
import { useNavigate } from "react-router"
import { formatDate } from "~/utils/formatDate"
import { Pagination, ContactListProps, FormValuesContact, ContactData } from "~/types/types"
import { Paths } from "~/constants"

const ContactFormList = ({ searchTerm }: ContactListProps) => {
  const { control, setValue } = useForm<FormValuesContact>({
    defaultValues: {
      pagination: {
        page: 1,
        pageSize: 5,
        current: 1,
        total: 0,
      },
    },
  })
  const dataForm = useWatch({ control, name: "dataForm" })
  const loading = useWatch({ control, name: "loading" })
  const pagination = useWatch({ control, name: "pagination" })
  const navigate = useNavigate()

  const fetchContatcFormData = async (paginate?: Pagination, searchTerm?: string) => {
    const response = await fetchContactForm(paginate?.current ?? 1, paginate?.pageSize ?? 5, searchTerm ?? "")
    return response
  }

  const handleFetchData = useCallback(
    async (paginate?: typeof pagination) => {
      setValue("loading", true)
      try {
        const response = await fetchContatcFormData(paginate, searchTerm)
        if (response?.error) {
          setValue("error", response?.error?.message)
          return
        }
        if (response?.data?.length > 0) {
          setValue("dataForm", response.data)
        } else {
          setValue("dataForm", [])
        }

        setValue("pagination", {
          ...pagination,
          total: response?.meta?.pagination.total ?? 0,
          current: response?.meta?.pagination.page ?? 1,
        })
        setValue("error", null)
      } catch (err) {
        setValue("error", err as string)
      } finally {
        setValue("loading", false)
      }
    },
    [setValue, searchTerm],
  )

  const handleEdit = (id: number) => {
    navigate(`${Paths.CONTACTFORM.EDIT.PATH}/${id}`)
  }

  const handleDelete = async (id: number) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this post?",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          setValue("loading", true)
          await deleteContactForm(id)
          setValue(
            "dataForm",
            dataForm.filter(post => post.id !== id),
          )
          handleFetchData()
          setValue("loading", false)
        } catch {
          message.error("Handle Delete Error")
        }
      },
    })
  }

  const onChange: TableProps<ContactData>["onChange"] = (pagination: TablePaginationConfig) => {
    const newPagination: Pagination = {
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 5,
      total: pagination.total || 0,
      page: pagination.current || 1,
    }
    setValue("pagination", newPagination)
    handleFetchData(newPagination)
  }

  useEffect(() => {
    handleFetchData()
  }, [handleFetchData])

  const columns: TableColumnsType<ContactData> = [
    {
      title: "Name",
      dataIndex: ["attributes", "nameContact"],
      key: "nameContact",
      width: 200,
    },
    {
      title: "Created Day",
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",
      width: 150,
      render: (createdAt: string) => {
        const dateResult = formatDate(createdAt)
        return (
          <span className="font-normal">
            Published
            <br />
            <span className="text-red-600">{dateResult.formattedTime}</span>
            {dateResult.formattedDate}
          </span>
        )
      },
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      className: "",
      render: (_, record) => (
        <Space size="middle" className="justify-center items-center">
          <Button
            type="primary"
            icon={<EditOutlined />}
            className="bg-blue-500 px-4 py-2 rounded"
            onClick={() => handleEdit(record.id)}
          >
            Edit
          </Button>
          <Button danger type="primary" icon={<DeleteOutlined />} className="" onClick={() => handleDelete(record.id)}>
            <span className="">Delete</span>
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div>
      <Table
        className="mt-3  border border-gray-300 custom-table"
        rowKey="id"
        rowSelection={{}}
        columns={columns}
        loading={loading}
        dataSource={dataForm || []}
        onChange={onChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        scroll={{ x: 1200 }}
        rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#f6f7f7] " : "bg-white")}
      />
    </div>
  )
}

export default ContactFormList
