import { CameraOutlined } from "@ant-design/icons"
import { Button, Form, Input, Modal } from "antd"
import React, { useState } from "react"
import ReactQuill, { Quill } from "react-quill"
import { configs } from "~/configs"
import { FieldType, MediaData, PostFormProps } from "~/types/types"
import { slugPattern } from "~/utils/validationPatterns"
import AddNewMedia from "../../media/pages/AddNewMedia"
import Media from "../../media/pages/Media"
import { checkSlug } from "../services/post"

const removeAccents = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[đĐ]/g, "d")
    .replace(/[âÂ]/g, "a")
    .replace(/[êÊ]/g, "e")
    .replace(/[.,:"'<>?`!@#$%^&*();/\\]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase()
}

const CONFIG_QUILL_MODULE = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    [{ color: [] }, { background: [] }],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
}

const CONFIG_QUILL_FORMAT = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
]

const PostForm: React.FC<PostFormProps> = ({
  form,
  onFinishFailed,
  quillValue,
  setQuillValue,
  onFinish,
  onChangeMetaImage,
}) => {
  const quillRef = React.useRef<ReactQuill>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChooseMedia, setIsChooseMedia] = useState(true)
  const [selectedMetaImage, setSelectedMetaImage] = useState<string | null>(null)
  const [modalMetaImage, setModalMetaImage] = useState(false)

  const handleOpenModal = (modalMetaImage: boolean) => {
    setModalMetaImage(modalMetaImage)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleSelectMedia = (media: MediaData) => {
    const quillEditor = quillRef.current?.getEditor()

    if (quillEditor) {
      const range = quillEditor.getSelection(true)
      quillEditor.insertEmbed(range.index, "image", `${configs.API_URL}${media.url}`)
    }

    setIsModalOpen(false)
  }

  const handleNameBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const nameValue = e.target.value.trim()
    if (!nameValue) {
      form.setFields([{ name: "slug", errors: [] }])
      return
    }

    const slugValue = removeAccents(nameValue)

    const isSlugUnique = async (slug: string): Promise<boolean> => {
      try {
        const response = await checkSlug(slug)
        return response.data.length === 0
      } catch {
        return false
      }
    }

    const isUnique = await isSlugUnique(slugValue)
    if (!isUnique) {
      form.setFieldsValue({ slug: slugValue })
      form.setFields([{ name: "slug", errors: ["Slug already exists. Please choose another slug."] }])
    } else {
      form.setFieldsValue({ slug: slugValue })
      form.setFields([{ name: "slug", errors: [] }])
    }
  }

  const isValidJson = (str: string): boolean => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const handleSelectMetaImage = (media: MediaData) => {
    setSelectedMetaImage(`${configs.API_URL}${media.url}`)
    onChangeMetaImage(media)
    setIsModalOpen(false)
  }

  return (
    <Form
      form={form}
      className="flex flex-col w-[77%] "
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item<FieldType>
        name="name"
        rules={[{ required: true, message: "Please input your username!" }]}
        className="py-2"
      >
        <Input placeholder="Add Name" className="text-xl custom-input" onBlur={handleNameBlur} />
      </Form.Item>

      <Form.Item<FieldType>
        name="slug"
        rules={[
          {
            pattern: slugPattern,
            message: 'Slug must match the following: "A-Z, a-z, 0-9, - ,_ ,. ,~"',
          },
        ]}
        label={<span className="text-base font-medium pb-2">Slug</span>}
        extra={<span className="text-sm ">May not need to be entered (automatically render by name)</span>}
      >
        <Input placeholder="Slug" className="custom-input" />
      </Form.Item>

      <Form.Item>
        <Button icon={<CameraOutlined />} onClick={() => handleOpenModal(false)}>
          Add Media
        </Button>
      </Form.Item>

      <Form.Item<FieldType> name="content" className="h-[399px]">
        <ReactQuill
          ref={quillRef}
          value={quillValue}
          onChange={setQuillValue}
          className="h-[350px] 	"
          modules={CONFIG_QUILL_MODULE}
          formats={CONFIG_QUILL_FORMAT}
        />
      </Form.Item>

      <Form.Item<FieldType> name="excerpt" label={<span className="text-base font-medium">Excerpt</span>}>
        <Input className="custom-input" />
      </Form.Item>

      <span className="text-base font-medium pt-2 pb-2">SEO</span>

      <div className="grid grid-cols-2 gap-x-5 gap-y-2 p-5 border rounded-[10px]">
        <Form.Item<FieldType>
          name="metaTitle"
          rules={[{ required: true, message: "Please input Meta Title" }]}
          label={<span className="text-base font-medium p">Meta Title</span>}
        >
          <Input className="custom-input py-2" />
        </Form.Item>

        <Form.Item<FieldType> name="keywords" label={<span className="text-base font-medium">Meta Keywords</span>}>
          <Input className="custom-input py-2" />
        </Form.Item>
        <Form.Item<FieldType>
          name="metaDescription"
          rules={[{ required: true, message: "Please input Meta Decription!" }]}
          label={<span className="text-base font-medium">Meta Description</span>}
        >
          <Input.TextArea className="custom-input py-2" rows={5} />
        </Form.Item>

        <Form.Item<FieldType>
          name="structuredData"
          label={<span className="text-base font-medium">Structured Data</span>}
          rules={[
            {
              validator: (_, value) => {
                if (value && !isValidJson(value)) {
                  return Promise.reject(new Error("Invalid JSON format!"))
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input.TextArea className="custom-input py-2" rows={5} placeholder='@example: {"id": "string"}' />
        </Form.Item>

        <Form.Item<FieldType> name="metaRobots" label={<span className="text-base font-medium">Meta Robots</span>}>
          <Input className="custom-input py-2" />
        </Form.Item>
        <Form.Item<FieldType> name="metaViewport" label={<span className="text-base font-medium">Meta Viewport</span>}>
          <Input className="custom-input py-2" />
        </Form.Item>
        {/* <Form.Item<FieldType> name="metaImage" label={<span className="text-base font-medium">Meta Image</span>}>
          <div className="h-[13vh]">
            <Button className="w-full h-full" onClick={() => handleOpenModal(true)}>
              {selectedMetaImage ? (
                <img src={selectedMetaImage} alt="Selected" className="w-full h-full object-cover" />
              ) : (
                "Select Meta Image"
              )}
            </Button>
          </div>
        </Form.Item> */}
      </div>

      <Modal
        open={isModalOpen}
        title={<span className="ml-4">Select Media</span>}
        onCancel={handleCloseModal}
        style={{ top: 20 }}
        width="95%"
        footer={null}
      >
        <div className="ml-4 mt-5">
          <Button
            onClick={() => setIsChooseMedia(true)}
            className="mr-2"
            style={{ backgroundColor: isChooseMedia ? "blue" : "initial", color: isChooseMedia ? "white" : "initial" }}
          >
            Select Media
          </Button>
          <Button
            onClick={() => setIsChooseMedia(false)}
            style={{
              backgroundColor: !isChooseMedia ? "blue" : "initial",
              color: !isChooseMedia ? "white" : "initial",
            }}
          >
            Upload Media
          </Button>
        </div>
        <div>
          {isChooseMedia ? (
            <Media
              isOpenModal={true}
              onSelectMedia={media => {
                modalMetaImage ? handleSelectMetaImage(media) : handleSelectMedia(media)
              }}
            />
          ) : (
            <AddNewMedia isOpenModal={true} setChooseMedia={setIsChooseMedia} />
          )}
        </div>
      </Modal>
    </Form>
  )
}

export default PostForm
