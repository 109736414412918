import Title from "antd/es/typography/Title"
import React, { useCallback, useEffect, useState } from "react"
import { message, notification } from "antd" // Import notification
import { fetchSeoSetting, updateSeoSetting } from "../services/seoSetting"
import { SEOData, SEOAttributes } from "~/types/types"
import SEOForm from "./FormSeoSetting"

const SeoListing: React.FC = () => {
  const [dataItem, setDataItem] = useState<SEOData | null>(null)

  const fetchSEOSettingData = useCallback(async () => {
    try {
      const response = await fetchSeoSetting()
      setDataItem(response.data)
    } catch (error) {
      message.error("Error fetching SEO settings:")
    }
  }, [])

  const handleFormSubmit = async (values: Partial<SEOAttributes>) => {
    if (!dataItem) return

    const updatedData: Partial<SEOAttributes> = {}

    if (values.metaTitle?.trim()) updatedData.metaTitle = values.metaTitle
    if (values.metaDescription?.trim()) updatedData.metaDescription = values.metaDescription
    if (values.metaKeywords?.trim()) updatedData.metaKeywords = values.metaKeywords

    if (values.MetaImage) updatedData.MetaImage = values.MetaImage

    try {
      await updateSeoSetting(updatedData)
      await fetchSEOSettingData()

      message.success("SEO settings updated successfully.")
    } catch (error) {
      message.error("Error submitting form:")
      message.error("Failed to update SEO settings.")
    }
  }

  useEffect(() => {
    fetchSEOSettingData()
  }, [fetchSEOSettingData])

  return (
    <div className="p-4">
      <div className="flex space-x-4 items-center">
        <Title level={2} className="m-0">
          SEO Setting
        </Title>
      </div>
      {dataItem && (
        <SEOForm
          onSubmit={handleFormSubmit}
          initialValues={{
            metaTitle: dataItem.attributes.metaTitle || "",
            metaDescription: dataItem.attributes.metaDescription || "",
            metaKeywords: dataItem.attributes.metaKeywords || "",
            MetaImage: dataItem.attributes.MetaImage?.data,
          }}
        />
      )}
    </div>
  )
}

export default SeoListing
