import { configureStore } from "@reduxjs/toolkit"

import loginReducer from "./auth/login.slice"

const store = configureStore({
  reducer: {
    account: loginReducer,
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
