import type { TabsProps } from "antd"
import { Button, Select, Space, Tabs } from "antd"
import Title from "antd/es/typography/Title"
import { debounce } from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { fetchAllContactForm } from "../services/contactForm"
import InputSearch from "./SearchInput"
import ContactFormList from "./ListContactForm"
import { ContactFormData } from "~/types/types"
import { Paths } from "~/constants"

const List: React.FC = () => {
  const [searchForm, setSearchForm] = useState<string>("")
  const [dataForm, setDataForm] = useState<ContactFormData[]>([])
  const navigate = useNavigate()

  const handleSearchChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = e.target.value.trim().replace(/\s+/g, " ").toLowerCase()
      setSearchForm(cleanedValue)
    }, 500),
    [],
  )

  const fetchContactFormData = useCallback(async () => {
    const response = await fetchAllContactForm()
    setDataForm(response.data)
    return response
  }, [])

  useEffect(() => {
    fetchContactFormData()
  }, [fetchContactFormData])

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `All (${dataForm?.length})`,
    },
  ]

  return (
    <div className="p-4 ">
      <div className="flex space-x-4 items-center">
        <Title level={2} className="m-0">
          Contact Form
        </Title>
        <Button
          onClick={() => navigate(Paths.CONTACTFORM.CREATE.PATH)}
          className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Contact Form
        </Button>
      </div>
      <div className="flex justify-between mt-3">
        <Tabs defaultActiveKey="1" items={items} />
        <InputSearch handleSearchChange={handleSearchChange} />
      </div>
      <div className="flex justify-between items-center">
        <Space className=" space-x-5">
          <Space className="space-x-3">
            <Select
              defaultValue="Bulk Action"
              style={{ width: 120 }}
              options={[{ value: "all", label: "Bulk Action" }]}
            />
            <Button>Apply</Button>
          </Space>
        </Space>
        <div>
          <span>{dataForm?.length ? `${dataForm.length} items` : ""}</span>
        </div>
      </div>

      <ContactFormList searchTerm={searchForm} />
    </div>
  )
}

export default List
