import { Route, Routes } from "react-router-dom"
import List from "./pages/List"
import AddContactForm from "./pages/AddContactForm"
import EditContact from "./pages/EditContactForm"

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/create" element={<AddContactForm/>} />
      <Route path="/:id" element={<EditContact />} />
    </Routes>
  )
}
