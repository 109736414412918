import type { TableColumnsType, TablePaginationConfig, TableProps, TabsProps } from "antd"
import { Button, Card, Image, message, Modal, Select, Space, Table, Tabs, Tag } from "antd"
import Title from "antd/es/typography/Title"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"
import "../css/List.css"
import { deletePost, fetchPosts } from "../services/post"
import { AttributesPost, CategoryData, FormValues, Pagination, PostData, TagData } from "~/types/types"
import { Paths } from "~/constants"
import { DeleteOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons"
import Filters, { FilterComponent } from "~/components/Filters"
import queryString from "query-string"
import { normalizeText } from "~/utils/helper"
import { formatDate } from "~/utils/formatDate"
import { useForm, useWatch } from "react-hook-form"
import { configs } from "~/configs"

const List: React.FC = () => {
  const [dataItem, setDataItem] = useState<PostData[]>([])
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const [postNameOptions, setPostNameOptions] = useState<{ value: string | number; label: string }[]>([])
  const [categoryOptions, setCategoryOptions] = useState<{ value: number; label: string }[]>([])
  const [tagOptions, setTagOptions] = useState<{ value: number; label: string }[]>([])
  const [filterTabPost, setFilterTabPost] = useState("all")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [bulkAction, setBulkAction] = useState<string>("")
  const navigate = useNavigate()

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const onRefresh = () => {
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
    setNeedRefresh(!needRefresh)
  }

  const fetchPostsData = async (searchTerms: { [key: string]: string } = {}) => {
    try {
      const response = await fetchPosts(searchTerms)

      if (response && response.length > 0) {
        setDataItem(response)

        setPostNameOptions(
          response.map((post: { id: number; attributes: AttributesPost }) => ({
            value: post.id,
            label: post.attributes.name,
          })),
        )
        const uniqueCategories = Array.from(
          new Map<number, { id: number; name: string }>(
            response
              .flatMap((post: { id: number; attributes: AttributesPost }) =>
                post.attributes.categories.data.map((category: CategoryData) => ({
                  id: category.id,
                  name: category.attributes.name,
                })),
              )
              .map(
                (category: { id: number; name: string }) =>
                  [category.id, category] as [number, { id: number; name: string }],
              ),
          ).values(),
        )

        setCategoryOptions(
          uniqueCategories.map((category: { id: number; name: string }) => ({
            value: category.id,
            label: category.name,
          })),
        )

        const uniqueTags = Array.from(
          new Map<string, { id: number; name: string }>(
            response
              .flatMap((post: { id: number; attributes: AttributesPost }) =>
                post.attributes.tags.data.map((tag: TagData) => ({
                  id: tag.id,
                  name: tag.attributes.name,
                })),
              )
              .map((tag: { id: number; name: string }) => [tag.id, tag] as [number, { id: number; name: string }]),
          ).values(),
        )
        setTagOptions(
          uniqueTags.map((tag: { id: number; name: string }) => ({
            value: tag.id,
            label: tag.name,
          })),
        )
      } else {
        setDataItem([])
        setPostNameOptions([])
        setCategoryOptions([])
        setTagOptions([])
      }

      return response
    } catch (error) {
      message.error("Failed to fetch posts. Please try again.")
    }
  }
  useEffect(() => {
    fetchPostsData()
  }, [filterValues, needRefresh, sortPaginationValues, filterTabPost])

  const draftPosts = dataItem?.filter(post => !post.attributes.publishedAt)
  const publishedPosts = dataItem?.filter(post => post.attributes.publishedAt)

  const onSearch = useCallback(
    (filters: object) => {
      const values: { [key: string]: string } = {}

      Object.keys(filters).forEach(key => {
        const value = filters[key as keyof typeof filters]
        if (typeof value === "string") {
          values[key] = value
        }
      })

      if (!values) {
        message.error("Values are undefined")
        return
      }

      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      const searchParams = queryString.stringify(newValue, { encode: false })
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues, navigate],
  )

  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}

    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
    setDefaultFilterValues(filterValues)
  }, [location.search])

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    fetchPostsData(params)
  }, [filterValues, sortPaginationValues, needRefresh])

  const handleEdit = (id: number) => {
    navigate(`${Paths.POSTS.EDIT.PATH}/${id}`)
  }

  const { control, setValue } = useForm<FormValues>({
    defaultValues: {
      pagination: {
        page: 1,
        pageSize: 5,
        current: 1,
        total: 0,
      },
    },
  })
  const loading = useWatch({ control, name: "loading" })

  const handleDelete = async (id: number) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this post?",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          setValue("loading", true)
          await deletePost(id)
          setValue(
            "dataItem",
            dataItem.filter(post => post.id !== id),
          )
          fetchPostsData()
          setValue("loading", false)
        } catch {
          message.error("Handle Delete Error")
        }
      },
    })
  }

  const handleBulkDelete = async () => {
    try {
      setValue("loading", true)
      const ids = selectedRowKeys.map(id => Number(id))
      await Promise.all(ids.map(id => deletePost(id)))
      setSelectedRowKeys([])
      fetchPostsData()
      setValue("loading", false)
      message.success("Posts deleted successfully")
    } catch {
      message.error("Handle Delete Error")
    }
  }

  const handleApplyBulkAction = () => {
    if (bulkAction === "delete" && selectedRowKeys.length > 0) {
      Modal.confirm({
        title: "Confirm Delete",
        content: "Are you sure you want to delete the selected posts?",
        okText: "Delete",
        cancelText: "Cancel",
        onOk: handleBulkDelete,
      })
    } else if (selectedRowKeys.length === 0) {
      message.warning("Please select posts to apply the action.")
    } else {
      message.warning("Please select a valid bulk action.")
    }
  }
  const handleChangeTab = (key: string) => {
    setFilterTabPost(key)
  }

  const onChange: TableProps<PostData>["onChange"] = (pagination: TablePaginationConfig) => {
    const newPagination: Pagination = {
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 5,
      total: pagination.total || 0,
      page: pagination.current || 1,
    }
    setValue("pagination", newPagination)
    fetchPostsData()
  }
  const columns: TableColumnsType<PostData> = [
    {
      title: <strong>Name</strong>,
      dataIndex: ["attributes", "name"],
      key: "name",
      showSorterTooltip: false,
      sorter: (a, b) => a.attributes.name.localeCompare(b.attributes.name),
      width: 200,
    },
    {
      title: <strong>Excerpt</strong>,
      dataIndex: ["attributes", "excerpt"],
      key: "excerpt",
      width: 300,
      render: (excerpt: string) => {
        return <span dangerouslySetInnerHTML={{ __html: excerpt }} className="truncate text-base" />
      },
      showSorterTooltip: false,
      sorter: (a, b) => (a.attributes?.excerpt || "").localeCompare(b.attributes?.excerpt || ""),
    },
    {
      title: <strong>Category</strong>,
      dataIndex: ["attributes", "categories"],
      key: "Categories",
      width: 100,
      render: (_, record) => {
        return (
          <>
            {(record?.attributes?.categories?.data || []).map((category: CategoryData) => {
              return <p key={category.id}>{category?.attributes?.name}</p>
            })}
          </>
        )
      },
      showSorterTooltip: false,
      sorter: (a, b) => a.attributes.categories.data.length - b.attributes.categories.data.length,
    },
    {
      title: <strong>Tags</strong>,
      key: "tags",
      dataIndex: ["attributes", "tags"],
      width: 100,
      showSorterTooltip: false,
      sorter: (a, b) => a.attributes.tags.data.length - b.attributes.tags.data.length,
      render: (_, record) => {
        const getTagColor = (name: string): string => {
          if (name === "special") return "volcano"
          return name.length > 5 ? "geekblue" : "green"
        }
        const renderTags = (tags: TagData[]) => {
          return tags.map(tag => {
            const tagName = tag?.attributes?.name ?? ""
            const color = getTagColor(tagName)

            return (
              <Tag key={tag.id} color={color}>
                {tagName}
              </Tag>
            )
          })
        }

        const tags = record?.attributes?.tags?.data ?? []

        return <>{tags.length > 0 ? renderTags(tags) : <span className="text-gray-400">—</span>}</>
      },
    },
    {
      title: <strong>Create At</strong>,
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",
      width: 150,
      render: (createdAt: string) => {
        const dateResult = formatDate(createdAt)
        return (
          <span className="font-normal">
            Published
            <br />
            <span className="text-red-600">{dateResult.formattedTime}</span>
            {dateResult.formattedDate}
          </span>
        )
      },
    },
    {
      title: <strong>Status</strong>,
      dataIndex: ["attributes", "publishedAt"],
      key: "status",
      width: 100,
      render: (publishedAt: string) => {
        return publishedAt ? (
          <span className="text-green-500 border-lime-200 bg-green-100 p-1 rounded">Published</span>
        ) : (
          <span className="text-red-500 bg-red-100 p-1 rounded">Draft</span>
        )
      },
    },
    {
      title: <strong>Action</strong>,
      key: "action",
      width: 100,
      className: "",
      render: (_, record) => (
        <Space size="middle" className="justify-center items-center">
          <Button
            type="primary"
            icon={<EditOutlined />}
            className="bg-blue-500 px-4 py-2 rounded"
            onClick={() => handleEdit(record.id)}
          >
            Edit
          </Button>
          <Button danger type="primary" icon={<DeleteOutlined />} className="" onClick={() => handleDelete(record.id)}>
            <span className="">Delete</span>
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="p-4 ">
      <div className="flex space-x-4 items-center">
        <Title level={2} className="m-0">
          Post
        </Title>
        <Button
          onClick={() => navigate(Paths.POSTS.CREATE.PATH)}
          className="bg-white text-blue-700 px-3 py-1 border-[1px] border-blue-700 rounded-[3px] hover:bg-blue-800 hover:text-white duration-300"
        >
          Add New Post
        </Button>
      </div>
      <div className="flex justify-between mt-3">
        <Tabs
          defaultActiveKey="all"
          activeKey={filterTabPost}
          onChange={handleChangeTab}
          items={[
            { label: `All (${dataItem?.length})`, key: "all" },
            { label: `Drafts (${draftPosts?.length})`, key: "draft" },
            { label: `Published (${publishedPosts?.length})`, key: "published" },
          ]}
        />
      </div>
      <div className="items-center">
        <Space className=" space-x-5 flex justify-between">
          <Space className="space-x-3">
            <Select
              defaultValue="Bulk Action"
              style={{ width: 120 }}
              onChange={value => setBulkAction(value)}
              options={[
                { value: "all", label: "Bulk Action" },
                { value: "delete", label: "Delete" },
              ]}
            />
            <Button onClick={handleApplyBulkAction}>Apply</Button>
          </Space>
          <Space>
            <Button onClick={onRefresh} icon={<ReloadOutlined />}>
              Làm mới
            </Button>
          </Space>
        </Space>
      </div>
      <Card type="inner" style={styles.card}>
        <Filters
          filterRef={filterRef}
          defaultFilterValues={defaultFilterValues}
          onSubmit={onSearch}
          filters={
            [
              {
                label: "Name",
                placeholder: "Find Post Name",
                name: "id",
                type: "select-search",
                options: postNameOptions,
              },
              {
                label: "Category",
                placeholder: "Find Category",
                name: "categories",
                type: "select-search",
                options: categoryOptions,
              },
              {
                label: "Tag",
                placeholder: "Find Tag",
                name: "tags",
                type: "select-search",
                options: tagOptions,
              },
            ] as FilterComponent[]
          }
        />
      </Card>
      <Table
        className="mt-3  border border-gray-300 custom-table"
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        loading={loading}
        dataSource={filterTabPost === "all" ? dataItem : filterTabPost === "draft" ? draftPosts : publishedPosts}
        onChange={onChange}
        pagination={{
          pageSize: 5,
        }}
        scroll={{ x: 1200 }}
        rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#f6f7f7] " : "bg-white")}
      />
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
