import { Route, Routes } from "react-router-dom"
import UpsertForm from "../settings/pages/UpsertForm"
import SettingsListing from "./pages/SettingsListing"

export const SettingRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SettingsListing />} />
        <Route path="/create" element={<UpsertForm editMode={false} />} />
        <Route path="/:id" element={<UpsertForm editMode={true} />} />
      </Routes>
    </div>
  )
}
