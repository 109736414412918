import { Breadcrumb } from "antd"
import { split, startsWith, trimStart } from "lodash"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import { MenuItem, menuItems } from "./layout/menuItems"

export const Breadcrumbs: FC = () => {
  const location = useLocation()
  const parts = split(trimStart(location.pathname, "/"), "/")
  const parent = menuItems.find(e => e.key && startsWith(parts[0], e.key as string))
  let breadcrumb: { title: string }[] = [{ title: "DongHwa" }]
  if (parent) {
    breadcrumb = [
      ...breadcrumb,
      //@ts-ignore
      { title: `${parent?.label}` },
    ]
    const children: MenuItem | null =
      // @ts-ignore
      parent.children && parent.children.length && parent.children.find(e => startsWith(location.pathname, e.key))
    if (children) {
      breadcrumb = [
        ...breadcrumb,
        //@ts-ignore
        { title: `${children?.label}` },
      ]
    }
  } else if (parent === undefined) {
    breadcrumb = [
      ...breadcrumb,
      //@ts-ignore
      { title: "Dashboard" },
    ]
  }
  return <Breadcrumb style={{ margin: "16px 0" }} items={breadcrumb} />
}
