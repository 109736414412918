import FormAddCategory from "./FormAddCategory"
import { Flex, Input, Button } from "antd"
import React, { useState } from "react"
import { SearchOutlined } from "@ant-design/icons"
import CategoryList from "./CategoryList"
import Title from "antd/es/typography/Title"

const List: React.FC = () => {
  const [refreshKey, setRefreshKey] = useState(0)
  const [searchTerm, setSearchTerm] = useState("")

  const handleCategoryAdded = () => {
    setRefreshKey(prevKey => prevKey + 1)
  }

  const handleSearch = () => {
    setRefreshKey(prevKey => prevKey + 1)
  }

  return (
    <div className="ml-4">
      <div style={{ justifySelf: "flex-start", justifyContent: "flex-start" }}>
        <Title level={2} className="mt-4">
          Categories
        </Title>
      </div>
      <Flex justify="flex-end" className="mb-[20px]">
        <Input className="w-[300px]" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
        <Button className="ml-[15px]" type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
          Search Category
        </Button>
      </Flex>
      <Flex justify="space-between" align="flex-start">
        <FormAddCategory onCategoryAdded={handleCategoryAdded} />
        {/* <CategoryFilter key={refreshKey} searchTerm={searchTerm} /> */}
        <CategoryList key={refreshKey} searchTerm={searchTerm} />
      </Flex>
    </div>
  )
}

export default List
