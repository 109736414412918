import React, { useState } from "react"
import { Form, Input, Button, Upload, message, Image } from "antd"
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import { UploadChangeParam } from "antd/lib/upload"
import { FileInfo, MetaImage } from "~/types/types"
import { configs } from "~/configs"
import { uploadFile } from "../services/seoSetting"

interface SEOFormProps {
  onSubmit: (values: any) => void
  initialValues: {
    metaTitle: string
    metaDescription: string
    metaKeywords: string
    MetaImage: MetaImage
  }
}

const SEOForm: React.FC<SEOFormProps> = ({ onSubmit, initialValues }) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState<string | null>(initialValues.MetaImage?.attributes?.url || null)
  const [file, setFile] = useState<File | null>(null)
  const [isChangeImage, setIsChangeImage] = useState(true)

  const handleFileChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      setIsChangeImage(false)
      setImageUrl(info.file.url || URL.createObjectURL(info.file.originFileObj!))
    } else if (info.file.status === "error") {
      message.error("File upload failed.")
    }
    setFile(info.file.originFileObj!)
  }

  const uploadImage = async (file: File) => {
    const formData = new FormData()
    formData.append("files", file)
    try {
      const response = await uploadFile(formData)
      return response.id
    } catch (error) {
      console.error("Error uploading image:", error)
      message.error("Image upload failed.")
      return null
    }
  }

  const handleRemoveImage = () => {
    setImageUrl(null)
    setFile(null)
    setIsChangeImage(true)
  }

  const onFinish = async (values: any) => {
    if (file) {
      const uploadedImageId = await uploadImage(file)
      if (uploadedImageId) {
        values.MetaImage = uploadedImageId
      }
    }
    onSubmit(values)
  }

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <Form.Item
        label="Meta Title"
        name="metaTitle"
        rules={[{ required: true, message: "Please input the meta title!" }]}
      >
        <Input placeholder="Enter meta title" />
      </Form.Item>

      <Form.Item
        label="Meta Description"
        name="metaDescription"
        rules={[{ required: true, message: "Please input the meta description!" }]}
      >
        <Input.TextArea rows={4} placeholder="Enter meta description" />
      </Form.Item>

      <Form.Item
        label="Meta Keywords"
        name="metaKeywords"
        rules={[{ required: true, message: "Please input the meta keywords!" }]}
      >
        <Input placeholder="Enter meta keywords (comma separated)" />
      </Form.Item>

      <Form.Item label="Meta Image" name="MetaImage" rules={[{ required: true, message: "please upload image" }]}>
        <div>
          <Upload
            name="file"
            listType="picture"
            showUploadList={false}
            customRequest={({ file, onSuccess, onError }) => {
              setFile(file as File)
              try {
                onSuccess?.({}, undefined)
              } catch (error) {
                onError?.(error as ProgressEvent)
              }
            }}
            onChange={handleFileChange}
          >
            <Button icon={<UploadOutlined />}>Select Meta Image</Button>
          </Upload>

          {imageUrl && (
            <div style={{ position: "relative", marginTop: 16 }}>
              <Image
                src={isChangeImage ? `${configs.API_URL}${imageUrl}` : imageUrl}
                alt="MetaImage"
                style={{ width: "300px", height: "auto" }}
              />
            </div>
          )}
        </div>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SEOForm
