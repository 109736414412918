import { HttpClient } from "~/api/HttpClient"
import { AxiosRequestConfig } from "axios"

export const API_PREFIX_POST_PATH = "/api/upload"

export const fetchMediaAll = async () => {
  return await HttpClient.get(`${API_PREFIX_POST_PATH}/files`)
}

export const fetchMedia = async (page: number, pageSize: number, searchTerm?: string) => {
  const params: AxiosRequestConfig["params"] = {
    populate: "*",
    sort: "createdAt:desc",
    pagination: {
      page,
      pageSize,
    },
    filters: {
      name: { $containsi: searchTerm },
    },
  }
  return await HttpClient.get(`${API_PREFIX_POST_PATH}/files`, { params })
}

export const fetchMediaId = async (id: number) => {
  const response = await HttpClient.get(`${API_PREFIX_POST_PATH}/files/${id}`)
  return response
}

export const deleteMedia = async (id: number) => {
  return await HttpClient.delete(`${API_PREFIX_POST_PATH}/files/${id}`)
}
export const updateMedia = async (id: number, data: FormData) => {
  return await HttpClient.post(`${API_PREFIX_POST_PATH}?id=${id}`, data)
}
export const addMedia = async (data: FormData) => {
  return await HttpClient.post(`${API_PREFIX_POST_PATH}`, data)
}
