export const filterOption = (input: string, option?: { label: string; value: string }) =>
  normalizeText((option?.label ?? "").toLowerCase()).includes(normalizeText(input.toLowerCase()))

export const normalizeText = (text: string) => {
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D")
}
