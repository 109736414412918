import { HttpClient } from "~/api/HttpClient"
import { PayloadSetting } from "~/types/types"
import { FormInstance, message } from "antd"
import { AxiosRequestConfig } from "axios"
import { useState } from "react"

const API_PREFIX_USERS_PATH = "/api/settings"

const useSettingService = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getAllSettings = async (searchTerm: string = "") => {
    setIsLoading(true)
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
      ...(searchTerm && {
        filters: {
          $or: [
            { id: { $containsi: searchTerm } },
            { key: { $containsi: searchTerm } },
            { value: { $containsi: searchTerm } },
            { description: { $containsi: searchTerm } },
          ],
        },
      }),
      sort: "id:DESC",
    }
    try {
      const response = await HttpClient.get(API_PREFIX_USERS_PATH, { params })
      return response
    } catch {
      message.error("Error while retrieving settings. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const getSettingById = async (id: number) => {
    setIsLoading(true)
    const params: AxiosRequestConfig["params"] = {
      populate: "*",
    }

    if (!id) {
      message.error("Invalid ID.")
      return null
    }
    try {
      const response = await HttpClient.get(`${API_PREFIX_USERS_PATH}/${id}`, { params })
      return response
    } catch {
      message.error("Error while retrieving setting. Please try again.")
      return null
    } finally {
      setIsLoading(false)
    }
  }

  const createSettings = async (PayloadSetting: PayloadSetting, form: FormInstance) => {
    setIsLoading(true)
    try {
      const response = await HttpClient.post(API_PREFIX_USERS_PATH, {
        data: {
          key: PayloadSetting.key,
          value: PayloadSetting.value,
          description: PayloadSetting.description,
        },
      })

      message.success("The setting has been successfully created.")
      form.resetFields()
      return response
    } catch {
      message.error("Error while creating setting. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const updateSetting = async (id: number, PayloadSetting: PayloadSetting) => {
    setIsLoading(true)
    if (!id) {
      message.error("Invalid ID.")
      return
    }
    try {
      const response = await HttpClient.put(`${API_PREFIX_USERS_PATH}/${id}`, {
        data: { key: PayloadSetting.key, value: PayloadSetting.value, description: PayloadSetting.description },
      })
      message.success("The setting has been successfully updated.")
      return response
    } catch {
      message.error("Error while updating setting. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const deleteSetting = async (id: number) => {
    setIsLoading(true)
    if (!id) {
      message.error("Invalid ID.")
    }
    try {
      const response = await HttpClient.delete(`${API_PREFIX_USERS_PATH}/${id}`)
      message.success("The setting has been successfully deleted.")
      return response
    } catch {
      message.error("Error while deleting setting. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const checkKeyExists = async (key: string) => {
    return await HttpClient.get(`${API_PREFIX_USERS_PATH}?filters[key][$eq]=${key}`)
  }

  return {
    isLoading,
    getAllSettings,
    getSettingById,
    createSettings,
    updateSetting,
    deleteSetting,
    checkKeyExists,
  }
}

export default useSettingService
