import { LockOutlined, MailOutlined } from "@ant-design/icons"
import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Checkbox, Form, Input, notification } from "antd"
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"

import { loginRequest } from "~/modules/auth/services/apiLogin"
import { doLoginAction } from "~/modules/auth/services/redux/auth/login.slice"
import { useAppDispatch } from "~/modules/auth/services/redux/hook"
import { loginSchema } from "~/validate/auth/validationAuthSchemas"

interface FormInputProps {
  email: string
  password: string
}

export default function Login() {
  const methods = useForm<FormInputProps>({
    values: { email: "", password: "" },
    resolver: yupResolver(loginSchema),
  })

  const { handleSubmit, control } = methods
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<FormInputProps> = async data => {
    try {
      const response = await loginRequest(data.email, data.password)
      if (response && response.jwt && response.user) {
        localStorage.setItem("access_token", response.jwt)
        localStorage.setItem("user", JSON.stringify(response.user))
        notification.success({
          message: "Login successful",
          description: "You have successfully logged in.",
          duration: 2,
        })
        dispatch(doLoginAction(response.user))
        navigate("/")
      } else {
        notification.error({
          message: "Login failed",
          description: "An error occurred while logging in. Please try again later.",
          duration: 2,
        })
      }
    } catch (error) {
      console.error("Login failed: ", error)
      notification.error({
        message: "Login failed",
        description: "An error occurred while logging in. Please try again later.",
        duration: 2,
      })
    }
  }

  return (
    <section className="flex items-center bg-gray-100 h-screen">
      <div className="mx-auto p-8 md:p-6 bg-white rounded-lg w-96 shadow-md">
        <div className="flex justify-center mb-6">
          <img className="w-[100px] object-cover object-center rounded-[50%] " src="/images/logocms.png" alt="Logo" />
        </div>
        <FormProvider {...methods}>
          <Form
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit(onSubmit)}
            layout="vertical"
            requiredMark="optional"
            className="space-y-4"
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.Item validateStatus={error ? "error" : ""} help={error?.message}>
                  <Input
                    {...field}
                    prefix={<MailOutlined className="pr-2" />}
                    placeholder="Email Address"
                    className="p-2 "
                    style={{ textIndent: "30px" }}
                  />
                </Form.Item>
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Form.Item validateStatus={error ? "error" : ""} help={error?.message}>
                  <Input.Password
                    {...field}
                    prefix={<LockOutlined className="pr-2" />}
                    placeholder="Password"
                    className="p-2 "
                  />
                </Form.Item>
              )}
            />

            <div className="flex justify-between items-center">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Link to="/forgot-password" className="text-blue-500 hover:text-blue-700">
                Forgot password?
              </Link>
            </div>

            <Form.Item>
              <Button block type="primary" htmlType="submit" className="bg-blue-500 hover:bg-blue-700">
                Log in
              </Button>
            </Form.Item>
          </Form>
        </FormProvider>
      </div>
    </section>
  )
}
