import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginState } from "../../../../../types/types";

const getLocalStorageData = (key: string) => {
  if (typeof window !== "undefined") {
    const data = localStorage.getItem(key);
    return data ? (key === "user" ? JSON.parse(data) : data) : null;
  }
  return null;
};

const initialState: LoginState = {
  isAuthenticated: Boolean(getLocalStorageData("user")) && Boolean(getLocalStorageData("access_token")),
  user: getLocalStorageData("user"),
  accessToken: getLocalStorageData("access_token"),
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    doLoginAction: (
      state,
      action: PayloadAction<{
        id: number;
        email: string;
        username: string;
        provider: string;
        confirmed: boolean;
        blocked: boolean;
        createdAt: string;
        updatedAt: string;
      }>
    ) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    doLogoutAction: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.accessToken = null;
      // other logout states
    },
  },
});

export const { doLoginAction, doLogoutAction } = loginSlice.actions;

export default loginSlice.reducer;
