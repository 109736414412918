import { HttpClient } from "../../../api/HttpClient"

export const loginRequest = async (identifier: string, password: string) => {
  try {
    const response = await HttpClient.post("/api/auth/local", {
      identifier,
      password,
    })
    return response
  } catch (error) {
    console.error("Error in callLogin:", error)
    throw error
  }
}
