import { Button, Form, Input, message } from "antd"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import useSettingService from "../service/settingService"
import { PayloadSetting } from "~/types/types"

const UpsertForm = ({ editMode = false }: { editMode?: boolean }) => {
  const { id } = useParams<{ id?: string }>()
  const [form] = Form.useForm()
  const { TextArea } = Input
  const settingServices = useSettingService()

  const fetchSettingData = async (settingId: number) => {
    try {
      const settingDataFetch = await settingServices.getSettingById(settingId)
      if (settingDataFetch?.data?.attributes) {
        form.setFieldsValue({
          key: settingDataFetch.data.attributes.key,
          value: settingDataFetch.data.attributes.value,
          description: settingDataFetch.data.attributes.description,
        })
      } else {
        message.error("Failed to fetch setting data.")
      }
    } catch {
      message.error("An error occurred while fetching setting data.")
    }
  }

  const handleKeyBlur = async () => {
    const key = form.getFieldValue("key")

    if (key) {
      try {
        const keyExists = await settingServices.checkKeyExists(key)

        if (keyExists?.data?.length > 0) {
          message.error("Key already exists.")
          form.setFields([
            {
              name: "key",
              errors: ["Key already exists"],
            },
          ])
          return false
        } else {
          form.setFields([{ name: "key", errors: [] }])
          return true
        }
      } catch {
        message.error("An error occurred while checking key existence.")
        return false
      }
    }
    return true
  }

  const handleFormSubmit = async (PayloadSetting: PayloadSetting) => {
    try {
      const isKeyValid = editMode || (await handleKeyBlur())
      if (!isKeyValid) {
        return
      }

      if (editMode) {
        await settingServices.updateSetting(Number(id), PayloadSetting)
        form.resetFields()
      } else {
        const res = await settingServices.createSettings(PayloadSetting, form)
        if (res?.status === 201) {
          message.success("The setting has been created successfully.")
          form.resetFields()
        }
      }
    } catch {
      message.error("An error occurred while creating/updating setting.")
    }
  }

  useEffect(() => {
    if (editMode && id) {
      fetchSettingData(Number(id))
    } else if (!editMode) {
      form.resetFields()
    }
  }, [id, editMode])

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 800 }}
        onFinish={handleFormSubmit}
        initialValues={editMode ? { id: id } : {}}
      >
        <Form.Item
          className="font-bold"
          label="Key"
          name="key"
          rules={[
            { required: true, message: "Account key cannot be left blank" },
            { pattern: /^[a-zA-Z0-9]+$/, message: "The account key cannot contain accents or special characters" },
            { min: 6, message: "Account key must be at least 6 characters" },
          ]}
        >
          <Input onBlur={handleKeyBlur} disabled={editMode} />
        </Form.Item>

        <Form.Item
          className="font-bold"
          label="Value"
          name="value"
          rules={[{ required: true, message: "Value cannot be left blank" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className="font-bold" label="Description" name="description">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {editMode ? "Edit" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default UpsertForm
