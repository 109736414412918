import { Button, Input, Radio, Select, Switch } from "antd"

interface ContentHomePageProps {
  title: string
  onRemove: () => void
}

export const ContentHomePage = ({ title, onRemove }: ContentHomePageProps) => {
  return (
    <div className="p-4">
      <span className=" text-lg mb-4">URL</span>
      <Input className="mb-4" value="#" />
      <span className="font-bold text-lg mb-4">Navigation Label</span>
      <Input className="mb-4" value={title} readOnly />

      <div className="flex items-center mb-4">
        <Button className="mr-2" disabled>
          Astra Menu Settings
        </Button>
        <Button className="text-blue-500">Unlock</Button>
      </div>

      <div className="mb-4">
        <Button type="link" className="mr-2 text-blue-500">
          Move
        </Button>
        <Button type="link" className="text-blue-500">
          Down one
        </Button>
      </div>

      <div className="mb-4">
        <Button className="font-bold w-full">
          Original: <span className="text-blue-500">{title}</span>
        </Button>
      </div>

      <div className="flex">
        <Button type="link" className="text-red-500 mr-2" onClick={onRemove}>
          Remove
        </Button>
        <Button type="link">Cancel</Button>
      </div>
    </div>
  )
}

export const ModalHomePage = ({ title }: { title: string }) => {
  return (
    <div className="max-h-[400px] overflow-y-auto relative ">
      <div className="flex justify-end sticky top-0 bg-white shadow-md pb-[10px] z-10">
        <Button>Menu Item: {title}</Button>
      </div>
      <div className="border border-1 bg-gray-300 my-[10px] p-[10px]">
        <span>General</span>
      </div>
      <div className="flex w-[300px] justify-between py-[10px]">
        <span>Enable Mega Menu</span>
        <Switch defaultChecked />
      </div>
      <div className="flex w-[300px] justify-between py-[10px]">
        <span>Mega Menu Content</span>
        <Switch defaultChecked />
      </div>
      <div className="flex w-[300px] justify-between py-[10px]">
        <span>Dropdown Position</span>
        <Select
          options={[
            { label: <span>Default</span>, value: "Default" },
            { label: <span>Relative</span>, value: "Relative" },
          ]}
          className="w-[100px]"
        />
      </div>
      <div className="flex w-[300px] justify-between py-[10px]">
        <span>Dropdown Width</span>
        <Select
          options={[
            { label: <span>Default</span>, value: "Default" },
            { label: <span>Relative</span>, value: "Relative" },
          ]}
          className="w-[100px]"
        />
      </div>
      <div className="flex w-[300px] justify-between py-[10px]">
        <span>Dropdown Width</span>
        <div className="flex flex-col border border-1 bg-gray-200 p-[10px]">
          <Radio disabled>Disabled</Radio>
          <Radio disabled>Disabled</Radio>
        </div>
      </div>
    </div>
  )
}

const menuContent = {
  ContentHomePage,
  ModalHomePage,
}

// eslint-disable-next-line react-refresh/only-export-components
export default menuContent
