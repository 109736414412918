import { CaretDownOutlined, CaretUpOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Checkbox, Input, List, message, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { AttributesCategory, TagOption, TagsProps } from "~/types/types"
import { createTag, fetchTags } from "../services/post"

const { Title, Link } = Typography

const Tags: React.FC<TagsProps> = ({ selectedTags, onChangeTags }) => {
  const [isTagsListVisible, setTagsListVisible] = React.useState(true)
  const [tags, setTags] = useState<TagOption[] | null>([])
  const [isAddingTag, setIsAddingTag] = useState(false)
  const [newTagName, setNewTagName] = useState("")

  const handleFetchTag = async () => {
    try {
      const response = await fetchTags()
      const tagsData = response.data
      if (Array.isArray(tagsData)) {
        const TagOptions = tagsData.map(tag => ({
          id: tag.id,
          label: tag.attributes.name,
          value: tag.id,
          disabled: false,
        }))
        setTags(TagOptions)
      }
    } catch {
      console.error("Error fetching tags")
    }
  }

  useEffect(() => {
    handleFetchTag()
  }, [])

  const handleAddTag = async () => {
    if (newTagName) {
      if (newTagName.length < 10) {
        return message.error("Name phải có ít nhất 10 ký tự")
      }
      try {
        const slug = newTagName
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, "-")
          .replace(/^-+|-+$/g, "")

        const data: AttributesCategory = {
          name: newTagName,
          slug: slug,
          description: "",
        }
        const response = await createTag(data)

        await handleFetchTag()

        setNewTagName("")
        setIsAddingTag(false)
        message.success(`Added new category: ${newTagName}`)
      } catch {
        message.error("Error adding category")
      }
    } else {
      message.error("Please enter a category name")
    }
  }

  return (
    <div className="tags border border-gray-300 rounded-[10px] mb-5">
      <div className="flex justify-between items-center px-4 py-2  border-b">
        <Title level={4} className="!m-0">
          Tags
        </Title>
        <Button
          onClick={() => setTagsListVisible(!isTagsListVisible)}
          className="flex items-center"
          icon={isTagsListVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
          type="text"
        ></Button>
      </div>

      {isTagsListVisible && (
        <div className="px-4 py-2 ">
          <Checkbox.Group onChange={onChangeTags} value={selectedTags} className="w-[100%] pt-2">
            <List
              className="border-[1px] border-gray-300 w-[100%]"
              size="small"
              dataSource={tags ?? []}
              renderItem={item => (
                <List.Item>
                  <Checkbox key={item.value} value={item.value} className="ml-2">
                    {item.label}
                  </Checkbox>
                </List.Item>
              )}
            />
          </Checkbox.Group>

          <div className="mt-4">
            <Link className="!underline text-base font-medium" onClick={() => setIsAddingTag(!isAddingTag)}>
              <PlusOutlined className="text-[10px]" /> Add New Tags
            </Link>
          </div>
          {isAddingTag && (
            <div className="mt-4 flex">
              <Input
                placeholder="Enter category name"
                value={newTagName}
                onChange={e => setNewTagName(e.target.value)}
                style={{ marginRight: "8px" }}
              />
              <Button type="primary" onClick={handleAddTag}>
                Add
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Tags
