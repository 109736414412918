import { FieldTypeContactForm, ContactFormProps } from "~/types/types"
import { slugPattern } from "~/utils/validationPatterns"
import { Form, Input } from "antd"
import React from "react"

const FormContact: React.FC<ContactFormProps> = ({ form, onFinishFailed, onFinish }) => {
  return (
    <Form
      form={form}
      className="w-[83%]"
      name="contact"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Form.Item<FieldTypeContactForm>
        label={<span className="text-base font-medium">Name Form</span>}
        name="nameContact"
        rules={[{ required: true, message: "Please input the contact name!" }]}
        className="!mb-0"
      >
        <Input placeholder="Contact Name" className="py-2 text-xl custom-input" />
      </Form.Item>
      <div className="flex space-x-4 mb-4">
        <Form.Item<FieldTypeContactForm>
          label={<span className="text-base font-medium">First Name</span>}
          name="firstName"
          rules={[{ required: true, message: "Please input the first name!" }]}
          className="!mb-0 w-1/2"
        >
          <Input placeholder="First Name" className="py-2 text-xl custom-input" />
        </Form.Item>

        <Form.Item<FieldTypeContactForm>
          label={<span className="text-base font-medium">Last Name</span>}
          name="lastName"
          rules={[{ required: true, message: "Please input the last name!" }]}
          className="!mb-0 w-1/2"
        >
          <Input placeholder="Last Name" className="py-2 text-xl custom-input" />
        </Form.Item>
      </div>

      <div className="flex space-x-4 mb-4">
        <Form.Item<FieldTypeContactForm>
          label={<span className="text-base font-medium">Email</span>}
          name="email"
          rules={[
            { required: true, message: "Please input the email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
          className="!mb-0 w-1/2"
        >
          <Input placeholder="Email" className="py-2 text-xl custom-input" />
        </Form.Item>

        <Form.Item<FieldTypeContactForm>
          label={<span className="text-base font-medium">Phone Number</span>}
          name="numberPhone"
          rules={[{ required: true, message: "Please input the phone number!" }]}
          className="!mb-0 w-1/2"
        >
          <Input placeholder="Phone Number" className="py-2 text-xl custom-input" />
        </Form.Item>
      </div>

      <Form.Item<FieldTypeContactForm>
        name="slug"
        rules={[
          {
            pattern: slugPattern,
            message: 'Slug must match the following: "A-Z, a-z, 0-9, - ,_ ,. ,~"',
          },
        ]}
        label={<span className="text-base font-medium">Slug</span>}
        extra={<span className="text-sm">May not need to be entered (automatically rendered by name)</span>}
      >
        <Input placeholder="Slug" className="py-2 custom-input" />
      </Form.Item>

      <Form.Item<FieldTypeContactForm>
        name="message"
        rules={[{ required: true, message: "Please input the message!" }]}
        label={<span className="text-base font-medium">Message</span>}
      >
        <Input.TextArea placeholder="Message" className="py-2 custom-input" rows={4} />
      </Form.Item>
    </Form>
  )
}

export default FormContact
