import React, { useCallback, useEffect, useState, useRef } from "react"
import {
  Button,
  Card,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  TableProps,
} from "antd"
import { useForm, useWatch } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import "../css/List.css"
import { Pagination, PageEditProps, PageData, FormValuesPage, PageAttributes } from "~/types/types"
import { DeleteOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons"
import { Paths } from "~/constants"
import pageService from "~/modules/page/services/page"
import Filters, { FilterComponent } from "~/components/Filters"
import { normalizeText } from "~/utils/helper"
import queryString from "query-string"

const List: React.FC = () => {
  const { control, setValue } = useForm<FormValuesPage>({
    defaultValues: {
      pagination: {
        page: 1,
        pageSize: 5,
        current: 1,
        total: 0,
      },
      dataItem: [],
      loading: false,
      error: "",
    },
  })

  const { deletePage, fetchAllPages } = pageService()
  const dataItem = useWatch({ control, name: "dataItem" })
  const loading = useWatch({ control, name: "loading" })
  const pagination = useWatch({ control, name: "pagination" })

  const [selected, setSelected] = useState("All")
  const [bulkAction, setBulkAction] = useState("")
  const navigate = useNavigate()
  const filterRef = useRef<{ filterFunction: () => void } | null>(null)
  const [sortPaginationValues, setSortPaginationValues] = useState<{ [key: string]: string } | null>(null)
  const [optionsNamePage, setOptionsNamePage] = useState<{ label: string; value: string }[]>([])
  const [needRefresh, setNeedRefresh] = useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<{ [key: string]: string } | null>(null)
  const [defaultFilterValues, setDefaultFilterValues] = useState<{ [key: string]: any } | null>(null)
  const fetchPagesData = async (filters = {}) => {
    const response = await fetchAllPages(filters)
    return response
  }

  const handleFetchData = async (searchTerms: { [key: string]: string } = {}) => {
    setValue("loading", true)
    try {
      const response = await fetchPagesData(searchTerms)

      if (response?.error) {
        setValue("error", response?.error?.message)
      } else {
        setValue("dataItem", response.data ?? [])

        setValue("error", "")

        setOptionsNamePage(
          response.data.map((page: { id: number; attributes: PageAttributes }) => ({
            label: page.attributes.name,
            value: page.id,
          })),
        )
      }
    } catch (err) {
      setValue("error", err as string)
    } finally {
      setValue("loading", false)
    }
  }

  const onRefresh = () => {
    if (filterRef.current) {
      filterRef.current.filterFunction()
    }
    setSortPaginationValues(null)
    setNeedRefresh(!needRefresh)
  }

  useEffect(() => {
    handleFetchData()
  }, [filterValues, needRefresh, sortPaginationValues])

  const onChange: TableProps<PageData>["onChange"] = (pagination: TablePaginationConfig) => {
    const newPagination: Pagination = {
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 5,
      total: pagination.total || 0,
      page: pagination.current || 1,
    }
    setValue("pagination", newPagination)
    handleFetchData()
  }
  const onSearch = useCallback(
    (filters: object) => {
      const values: { [key: string]: string } = {}

      Object.keys(filters).forEach(key => {
        const value = filters[key as keyof typeof filters]
        if (typeof value === "string") {
          values[key] = value
        }
      })

      if (!values) {
        message.error("Values are undefined")
        return
      }

      let newValue = { ...values, ...sortPaginationValues }

      if (values.search) {
        newValue = { ...newValue, search: normalizeText(values.search) }
      }

      const searchParams = queryString.stringify(newValue, { encode: false })
      const newUrl = searchParams.length > 0 ? `${location.pathname}?${searchParams}` : location.pathname
      navigate(newUrl)
    },
    [sortPaginationValues, navigate],
  )
  useEffect(() => {
    const searchParams = queryString.parse(location.search)
    const filterValues: { [key: string]: string } = {}
    const sortPaginationValues: { [key: string]: string } = {}

    for (const key of Object.keys(searchParams)) {
      const value = searchParams[key]
      if (value && typeof value === "string") {
        if (key === "page" || key === "limit" || value === "asc" || value === "desc") {
          sortPaginationValues[key] = value
        } else {
          filterValues[key] = value
        }
      }
    }

    setFilterValues(filterValues)
    setSortPaginationValues(sortPaginationValues)
    setDefaultFilterValues(filterValues)
  }, [location.search])

  useEffect(() => {
    if (!filterValues && !sortPaginationValues) return
    const params = { ...filterValues, ...sortPaginationValues }
    handleFetchData(params)
  }, [filterValues, sortPaginationValues, needRefresh])
  const handleAddNewPage = () => {
    navigate(`${Paths.PAGES.CREATE.PATH}`)
  }

  const handleEditPage = (id: number) => {
    navigate(`${Paths.PAGES.EDIT.PATH}/${id}`)
  }

  const handleDeletePage = async (id: number) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this post?",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          setValue("loading", true)
          await deletePage(id)
          setValue(
            "dataItem",
            dataItem.filter(page => page.id !== id),
          )
          handleFetchData()
          setValue("loading", false)
        } catch {
          message.error("Handle Delete Error")
        }
      },
    })
  }

  const handleBulkAction = () => {
    if (bulkAction === "delete") {
      message.info("Bulk delete action not implemented.")
    } else if (bulkAction === "move") {
      message.info("Bulk move action not implemented.")
    }
  }

  const columns: TableColumnsType<PageData> = [
    {
      title: "Name",
      dataIndex: ["attributes", "name"],
      key: "name",
      width: 500,
    },
    {
      title: "Date",
      dataIndex: ["attributes", "createdAt"],
      key: "createdAt",
      width: 300,
      align: "center",
      render: (createdAt: string) => {
        const date = new Date(createdAt)
        const formattedDate = `${date.getUTCDate().toString().padStart(2, "0")}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCFullYear()}`
        return <span>{formattedDate}</span>
      },
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      align: "center",
      render: (record: PageEditProps) => (
        <>
          <Space size="middle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              type="primary"
              icon={<EditOutlined />}
              className="bg-blue-500 px-4 py-2 rounded"
              onClick={() => handleEditPage(record.id)}
            >
              Edit
            </Button>
            <Button danger type="primary" icon={<DeleteOutlined />} onClick={() => handleDeletePage(record.id)}>
              <span>Delete</span>
            </Button>
          </Space>
        </>
      ),
    },
  ]

  return (
    <div className="p-4 rounded-sm">
      <div className="flex items-center space-x-4">
        <h1 className="text-3xl font-semibold">Pages</h1>
        <Button
          className="bg-blue-700 text-white px-5 py-2 rounded-lg hover:bg-blue-800 duration-300"
          onClick={handleAddNewPage}
        >
          Add New Page
        </Button>
      </div>

      <div className="flex justify-between items-center mt-4">
        <div className="flex space-x-4">
          {["All", "Published", "Drafts", "Move"].map(item => (
            <span
              key={item}
              className={`relative cursor-pointer text-sm pb-2 ${
                selected === item ? "text-blue-600" : "text-black-600 hover:text-blue-400"
              }`}
              onClick={() => setSelected(item)}
            >
              {item} {item === "All" && dataItem?.length ? `(${dataItem.length})` : ""}
              <span
                className={`absolute bottom-0 left-0 h-0.5 bg-blue-600 transition-all duration-300 ease-in-out ${
                  selected === item ? "w-full" : "w-0"
                }`}
              />
            </span>
          ))}
        </div>
      </div>

      <div className="flex items-center space-x-3 mt-4 justify-between">
        <Space>
          <div className="flex items-center space-x-3">
            <Select
              className="border-gray-300 rounded-lg"
              placeholder="Bulk Action"
              value={bulkAction || undefined}
              onChange={value => setBulkAction(value)}
              style={{ width: 120 }}
            >
              <Select.Option value="delete">Delete</Select.Option>
              <Select.Option value="move">Move</Select.Option>
            </Select>
            <Button
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 duration-300"
              onClick={handleBulkAction}
            >
              Apply
            </Button>
          </div>

          <div className="flex space-x-3">
            <Select className="border-gray-300 rounded-lg" placeholder="Author" style={{ width: 120 }}>
              {["Admin", "Users", " ... "].map(author => (
                <Select.Option key={author} value={author}>
                  {author}
                </Select.Option>
              ))}
            </Select>
            <Button className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 duration-300">
              Filter
            </Button>
          </div>
        </Space>

        <Space>
          <Button onClick={onRefresh} icon={<ReloadOutlined />}>
            Làm mới
          </Button>
        </Space>
      </div>
      <Card type="inner" style={styles.card}>
        <Filters
          filterRef={filterRef}
          defaultFilterValues={defaultFilterValues}
          onSubmit={onSearch}
          filters={
            [
              {
                label: "Name",
                placeholder: "Find Page Name",
                name: "id",
                type: "select-search",
                options: optionsNamePage,
              },
            ] as FilterComponent[]
          }
        />
      </Card>
      <div>
        <Table
          className="mt-3  border border-gray-300 custom-table"
          rowKey="id"
          rowSelection={{}}
          columns={columns}
          loading={loading}
          dataSource={dataItem || []}
          onChange={onChange}
          pagination={{
            pageSize: 5,
          }}
          scroll={{ x: 1200 }}
          rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#f6f7f7] " : "bg-white")}
        />
      </div>
    </div>
  )
}

export default List

const styles = {
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  card: {
    marginTop: "1.25rem",
    marginBottom: "1.25rem",
  },
}
