import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import type { TableColumnsType, TablePaginationConfig, TableProps } from "antd"
import { Button, message, Modal, Space, Table } from "antd"
import { useCallback, useEffect } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useNavigate } from "react-router"
import { CategoryData, FormValuesCategory, Pagination, PostResponse } from "~/types/types"
import { Paths } from "~/constants"
import { deleteCategory, fetchCategories } from "../services/category"

interface CategoryFilterProps {
  searchTerm: string
  key: number
}

const CategoryList = ({ searchTerm, key }: CategoryFilterProps) => {
  const { control, setValue } = useForm<FormValuesCategory>({
    defaultValues: {
      pagination: {
        page: 1,
        pageSize: 5,
        current: 1,
        total: 0,
      },
    },
  })
  const dataItem = useWatch({ control, name: "dataItem" })
  const loading = useWatch({ control, name: "loading" })
  const pagination = useWatch({ control, name: "pagination" })
  const navigate = useNavigate()

  const fetchCategory = async (paginate?: Pagination, searchTerm?: string) => {
    const response = await fetchCategories(paginate?.current ?? 1, paginate?.pageSize ?? 5, searchTerm ?? "")
    return response
  }

  const handleFetchData = useCallback(
    async (paginate?: typeof pagination) => {
      setValue("loading", true)
      try {
        const response = await fetchCategory(paginate, searchTerm)
        if (response?.error) {
          setValue("error", response?.error?.message)
          return
        }
        if (response?.data?.length > 0) {
          setValue("dataItem", response.data)
        } else {
          setValue("dataItem", [])
        }

        setValue("pagination", {
          ...pagination,
          total: response?.meta?.pagination.total ?? 0,
          current: response?.meta?.pagination.page ?? 1,
        })
        setValue("error", null)
      } catch (err) {
        setValue("error", err as string)
      } finally {
        setValue("loading", false)
      }
    },
    [setValue, searchTerm],
  )

  const handleEdit = (id: number) => {
    navigate(`${Paths.CATEGORIES.EDIT.PATH}/${id}`)
  }

  const handleDelete = async (id: number) => {
    Modal.confirm({
      title: "Confirm Delete",
      content: "Are you sure you want to delete this post?",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          setValue("loading", true)
          await deleteCategory(id)
          setValue(
            "dataItem",
            dataItem.filter(post => post.id !== id),
          )
          handleFetchData()
          setValue("loading", false)
        } catch {
          message.error("Handle Delete Error")
        }
      },
    })
  }

  const onChange: TableProps<CategoryData>["onChange"] = (pagination: TablePaginationConfig) => {
    const newPagination: Pagination = {
      current: pagination.current || 1,
      pageSize: pagination.pageSize || 5,
      total: pagination.total || 0,
      page: pagination.current || 1,
    }
    setValue("pagination", newPagination)
    handleFetchData(newPagination)
  }

  useEffect(() => {}, [pagination])

  useEffect(() => {
    handleFetchData()
  }, [handleFetchData, key])

  const columns: TableColumnsType<CategoryData> = [
    {
      title: "name",
      dataIndex: ["attributes", "name"],
      width: "25%",
    },
    {
      title: "description",
      dataIndex: ["attributes", "description"],
      width: "20%",
    },
    {
      title: "slug",
      dataIndex: ["attributes", "slug"],
      width: "20%",
    },
    {
      title: "count",
      dataIndex: ["attributes", "posts", "data"],
      width: "10%",
      render: (data: PostResponse[]) => data.length || 0,
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      className: "",
      render: (_, record) => (
        <Space size="middle" className="justify-center items-center">
          <Button
            type="primary"
            icon={<EditOutlined />}
            className="bg-blue-500 px-4 py-2 rounded"
            onClick={() => handleEdit(record.id)}
          >
            Edit
          </Button>
          <Button danger type="primary" icon={<DeleteOutlined />} className="" onClick={() => handleDelete(record.id)}>
            <span className="">Delete</span>
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="w-[60%] mt-8">
      <Table
        className="mt-3 border border-gray-300 custom-table"
        rowKey="id"
        rowSelection={{}}
        columns={columns}
        loading={loading}
        dataSource={dataItem || []}
        onChange={onChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        scroll={{ x: 1000, y: 500 }}
        rowClassName={(record, index) => (index % 2 === 0 ? "bg-[#f6f7f7] " : "bg-white")}
      />
    </div>
  )
}

export default CategoryList
