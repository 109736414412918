import { useAppSelector } from "~/modules/auth/services/redux/hook"
import React, { ReactNode, useEffect, useState } from "react"
import { Navigate, Outlet, useNavigate } from "react-router-dom"

interface RequireAuthProps {
  children?: ReactNode
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const isAuthenticated = useAppSelector(state => state.account.isAuthenticated)
  const [isAuthChecked, setIsAuthChecked] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      setIsAuthChecked(true)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/", { replace: true })
    }
  }, [isAuthenticated, navigate])

  if (!isAuthChecked) {
    return null
  } else {
    if (isAuthenticated) {
      return <Navigate to="/" replace />
    } else {
      return children ? <>{children}</> : <Outlet />
    }
  }
}

export default RequireAuth
